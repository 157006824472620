import React, { useEffect } from 'react';
import { RouterApp } from './core/Router';
import {RouterProvider} from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import ThemeApp from './core/Theme';
import { useAuth} from 'react-oidc-context';
import SocketContextProvider from './core/Contexts/SocketContext';
import AutheticationContextProvider from './core/Contexts/AutheticationContext';
import useIdleTimeOutHook from './core/Hooks/IdleTimeOutHook';

const handleIdle = () => {
  window.location.reload();

}

function App() {
  const auth = useAuth();
  useIdleTimeOutHook({onIdle: handleIdle, idleTime: 60*5})
   
  
  if (auth.error) {
    console.log('Erro Keycloak', auth.error)
  }


  return (
  <ThemeProvider theme={ThemeApp}>
    <AutheticationContextProvider>
      <SocketContextProvider>
        <RouterProvider router={RouterApp} />
      </SocketContextProvider>
    </AutheticationContextProvider>
  </ThemeProvider>
  );
}

export default App;
