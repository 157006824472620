import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { useMediaQuery, useTheme } from '@mui/material';

import { useAccreditedStep } from '../../../contexts/AccreditedStepContext';

import MobileControlStepForm from './MobileControlStepForm';
import WebControlStepForm from './WebControlStepForm';

export default function ControlStepForm({onFinalize = ()=>{}, canMoveForward = true}) {
  const {handleBack} = useAccreditedStep();
  const [canMove, setCanMove] = React.useState<boolean>(true);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true
});


  const handleBackStep = () => {
    handleBack()
  }; 

  useEffect(()=>{
    setCanMove(canMoveForward)
  }, [canMoveForward])

  return (
      <Grid container sx={{pt: 1,pl:'-16px', pr: -1, width:'100%'}} justifyContent={"space-between"}>
        {isMobile ? (
        <MobileControlStepForm onFinalize={onFinalize} canMoveForward={canMoveForward}/>
        ):(
          <WebControlStepForm onFinalize={onFinalize} canMoveForward={canMoveForward}/>
        )}
      </Grid>
          
  );
}
