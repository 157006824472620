import { CompanyBranchProps } from "..";
import { authFetch } from "../../../../services/fetch";

export async function getCompanyBranch(): Promise<CompanyBranchProps[]> {
  try {
    const response = await authFetch({
      url: "/company-branch",
      method: "GET",
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
}
