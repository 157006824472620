import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { createAccredited } from '../services/AccreditedService';
import { useNavigate } from "react-router-dom";
import notify from '../../../utils/notify';
import SplashFeedback from '../../../core/Feedbacks/SplashFeedback';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { Typography } from '@mui/material';
import SimpleDialogFeedback from '../../../core/Feedbacks/SimpleDialogFeedback';

interface IAccreditedFormType {
  fields: any
  mergeFieldsInState: (field:any) => void
  sendToApi: () => void
  hasError: boolean
}

type AccreditedFormContextProviderProps = {
  readonly children: ReactNode;
}

export const AccreditedFormContext = createContext<IAccreditedFormType>({} as IAccreditedFormType);

export function AccreditedFormContextProvider({children}: AccreditedFormContextProviderProps) {
  const [fields, setFields] = React.useState<any>(null);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [openTemporaryPassword, setOpenTemporaryPassword] = React.useState<boolean>(false);
  const navigate = useNavigate();

 
    const mergeFieldsInState = async (fieldsInput: any) => {
      if(fields !== null)
        setFields( Object.assign(fields, fieldsInput))
      else
        setFields( fieldsInput)
    }

    const sendToApi = async () =>{
      setIsLoading(true)
      const { hasError, data, error }  = await createAccredited(fields)
      console.log(error, 'error')
      if(!hasError){
        setHasError(false)
        setIsLoading(false)
        setOpenTemporaryPassword(true)
       
      }else{
        if (error?.invalid_params && error?.invalid_params.length) {
          error.invalid_params?.map((item: { reason: string }) =>
            notify({
              message: `${item.reason}`,
              type: "error",
            })
          );
        } else if (error?.details?.length) {
          notify({
            message: `${error.details}`,
            type: "error",
          });
        } else {
          notify({
            message: `Algo deu errado, tente novamento mais tarde.`,
            type: "error",
          });
        }
        setIsLoading(false)
        setHasError(true)
      }
    }

    const onConfirm = () =>{
      navigate("/")
    }


    return (
      <AccreditedFormContext.Provider 
        value={{
          mergeFieldsInState,
          fields,
          sendToApi,
          hasError
        }}>
        <SimpleDialogFeedback 
          open={openTemporaryPassword} 
          title='Cadastro Credenciada' 
          onCloseDialog={onConfirm}
          onConfirm={onConfirm}
          content={
           
              'Para fazer login, o coloborador credenciado deve utilizar o <b>CPF</b> e a <b>senha temporária enviada por email</b>.'
          } 
            
        />
        <SplashFeedback open={isLoading}/>
        {children}
      </AccreditedFormContext.Provider>
    );
  }

  export function useAccreditedForm() {
    return useContext(AccreditedFormContext);
  }