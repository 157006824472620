import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { useForm } from 'react-hook-form';
import SimpleInputFieldForm from '../../../../components/Form/components/Fields/SimpleInputFieldForm';
import { useAccreditedStep } from '../../contexts/AccreditedStepContext';
import CEPFieldForm from '../../../../components/Form/components/Fields/CEPFieldForm';
import ControlStepForm from './Steps/ControlStepForm';
import { useAccreditedForm } from '../../contexts/AccreditedFormContext';
import notify from '../../../../utils/notify';


export default function AddressAccreditedForm() {
  const {handleNext} = useAccreditedStep();
  const {mergeFieldsInState, fields} = useAccreditedForm()
  const [address, setAddress] = useState<any>(null)
  const [isLookingForCEP, setIsLookingForCEP] = useState<boolean>(false)


  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {isValid },
    trigger,
    setError,
    setFocus,
    clearErrors
  } = useForm({
     mode: "all" , 
     defaultValues: {
      city: '',
      street: '',
      neighborhood: '',
      state: '',
      cep: ''

    } 
    });

  const submitForm = async (event:any) => {
    try {
      if (isValid){
        handleNext()
        mergeFieldsInState(event)
      }
      
    } catch (e) {
      console.log(e, 'submit')
    }
    

   }

   const getAddressByViaCEPApi = async (cep: string) => {
    setIsLookingForCEP(true)

    try {


      const fetchResponse = await fetch(`http://viacep.com.br/ws/${cep.replace('-', '')}/json/`)
      const response = await fetchResponse.json()
      if(!Boolean(response?.erro)){
        setAddress(response)
        setValue('street', response.logradouro)
        setValue('city', response.localidade)
        setValue('neighborhood', response.bairro)
        setValue('state', response.estado)
        setIsLookingForCEP(false)
        clearErrors()
      }else{
        setValue('street', '')
        setValue('city', '')
        setValue('neighborhood','')
        setValue('state','')
        notify({
          message: `O cep: ${cep} é inválido.`,
          type: "error",
        });
        trigger()
        setIsLookingForCEP(false)

      }
    } catch (e){
      console.log('Erro ao consultar via CEP:', e)
      notify({
        message: `Erro ao consultar via CEP.`,
        type: "error",
      });
      trigger()
      setIsLookingForCEP(false)

    } 
   } 


  return (
    <Grid container component="form" sx={{padding: 2}} spacing={2} onSubmit={handleSubmit(submitForm)}>
      <Grid size={{xs:12, sm: 6, md:3, lg:2}}>
        <CEPFieldForm 
          name='zip_code' 
          required={true}  
          value= {fields?.zip_code} 
          id='cep_input'
          register={register} 
          control={control}
          setValue={setValue}
          onBlurCEP={getAddressByViaCEPApi}
          disabled={isLookingForCEP}
          //showErroMessagemLookingForCep={address === undefined}
          isLookingForCEP={isLookingForCEP}

        />
      </Grid>
               
    <Grid size={{xs:12, sm: 6, md:6, lg:6, xl:5}}>
      <SimpleInputFieldForm 
        name='street' 
        label='Endereço' 
        required={true}  
        messageError='O endereço é obrigatório' 
        value= {!address?.logradouro? fields?.street: address?.logradouro} 
        id='street_id'
        register={register} 
        control={control}
        setValue={setValue}
        disabled={isLookingForCEP}
       

      />
    </Grid>
    <Grid size={{xs:12, sm: 4, md:1}}>
      <SimpleInputFieldForm 
        name='number' 
        label='Número'   
        value= {fields?.number}  
        id='neighborhood_input'
        register={register} 
        control={control}
        setValue={setValue}
        required={true}  

      />
    </Grid>
    <Grid size={{xs:12,  sm: 6, md:4, lg:3}}>
      <SimpleInputFieldForm 
        name='neighborhood' 
        label='Bairro' 
        messageError='Bairro é obrigatório' 
        value= { !address?.bairro? fields?.neighborhood: address?.bairro} 
        id='neighborhood_input'
        register={register} 
        control={control}
        setValue={setValue}
        required={true} 
        disabled={isLookingForCEP} 

      />
    </Grid>
    <Grid size={{xs:12,  sm: 6, md:3, lg:2}}>
      <SimpleInputFieldForm 
        name='city' 
        label='Cidade' 
        messageError='Cidade é obrigatório' 
        value= { !address?.localidade? fields?.city: address?.localidade} 
        id='city_id'
        register={register} 
        control={control}
        setValue={setValue}
        required={true} 
        disabled={isLookingForCEP} 

      />
    </Grid>
       <Grid size={{xs:12,  sm: 6, md:3, lg:2}}>
      <SimpleInputFieldForm 
        name='state' 
        label='Estado' 
        messageError='Estado é obrigatório' 
        value= {!address?.estado? fields?.state: address?.estado} 
        id='state_input'
        register={register} 
        control={control}
        setValue={setValue}
        required={true}  
        disabled={isLookingForCEP}

      />
    </Grid>
    
 
    <Grid size={{xs:12, sm: 6, md:6, lg:4}}>
      <SimpleInputFieldForm 
        name='complement' 
        label='Complemento'   
        value= {fields?.complement} 
        id='complement_input'
        register={register} 
        control={control}
        setValue={setValue}

      />
    </Grid>
 
    <ControlStepForm canMoveForward={isValid}/>
    
    </Grid>
           

  );
}

