import PrivateRoute from "../../core/PrivateRouter";
import Dashboard from "./pages";
import PanelPage from "./pages/PanelPage";


export const DashboardRoutes = [
   // { path: '/painel', element: <PrivateRoute Group={['integrador']}><Dashboard /></PrivateRoute> },
    { path: '/admin/painel', element: <PrivateRoute><PanelPage /></PrivateRoute> },


];