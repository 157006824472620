import { IDataTable } from "../../../components/CrudIndex";
import { authFetch } from "../../../services/fetch";
import { ICitizen } from "../../Tracks/components/SearchCitizenTab";
import { ITrack } from "../../Tracks/interface";
import { IEntry } from "../interfaces";

export interface IError {
  readonly type: string;
  readonly title: string;
  readonly details: string;
  readonly status: number;
  readonly instance: string;
  readonly invalid_params: {
    readonly name: string;
    readonly reason: string;
  }[];
}

export async function createAttendant(data: any) {
  try {
    const response = await authFetch({
      url: "/users/create",
      data: {
        group: "teleatendente",
        ...data,
      },
    });
    return { hasError: false, data: response.data };
  } catch (error) {
    console.error("~ createAttendant: ", error);
    return { hasError: true, error: error as IError };
  }
}

export async function getUsers(): Promise<readonly IDataTable[]> {
  try {
    const { data } = await authFetch({
      url: "/users",
      method: "GET",
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getUserById(id: string | null): Promise<ICitizen> {
  try {
    const { data } = await authFetch({
      url: `/users/${id}`,
      method: "GET",
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export async function editUser(id: string | undefined, data: any) {
  try {
    const response = await authFetch({
      url: `/users/update/${id}`,
      method: "PATCH",
      data: data,
    });
    return { hasError: false, data: response.data };
  } catch (error) {
    console.error("~ editUser: ", error);
    return { hasError: true, error: error as IError };
  }
}

export async function deleteUser(id: string) {
  return await authFetch({
    url: `/users/delete/${id}`,
    method: "DELETE",
  });
}

export async function createOperator(data: any) {
  try {
    const response = await authFetch({
      url: "/users/create",
      data: {
        group: "integrador",
        role: "operador",
        ...data,
      },
    });
    return { hasError: false, data: response.data };
  } catch (error) {
    console.error("~ createOperator: ", error);
    return { hasError: true, error: error as IError };
  }
}

export async function getTracksPendingInstallation(filters: {company_branch_id: number} | {}): Promise<
  readonly ITrack[]
> {
  try {
    const { data } = await authFetch({
      url: "/tracker/pending",
      method: "GET",
      params: {
        filters: JSON.stringify(filters)
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getIntegrator(page = 0 , limit = 100, sort=[], filters={}): Promise<readonly IDataTable[]> {
  try {
    const { data } = await authFetch({
      url: "/users/group-role",
      method: "GET",
      params: {
        group: 'integrador',
        page: page,
        limit: limit,
        orders: JSON.stringify(sort),
        filters: JSON.stringify(filters)
      }
    });
    return data;
  } catch (error) {
    throw error;
  }
}
