import PrivateRoute from "../../core/PrivateRouter";
import CreateAttendant from "./pages/Attendant/CreateAttendant";
import EditAttendant from "./pages/Attendant/EditAttendant";
import ListAttendant from "./pages/Attendant/ListAttendant";
import CreateOperator from "./pages/Operator/CreateOperator";
import EditOperator from "./pages/Operator/EditOperator";
import ListOperator from "./pages/Operator/ListOperator";
import ListScheduling from "./pages/Scheduling/ListScheduling";
import TrackServices from "../Tracks/pages/TrackServices";

export const licensedRoutes = [
    { path: '/operator/create', element: <PrivateRoute Group={['integrador']}><CreateOperator /></PrivateRoute> },
    { path: '/cadastro/operador', element: <PrivateRoute Group={['integrador']}><CreateOperator /></PrivateRoute> },
    { path: '/operator', element: <PrivateRoute Group={['integrador']}><ListOperator /> </PrivateRoute> },
    { path: '/operador', element: <PrivateRoute Group={['integrador']}><ListOperator /> </PrivateRoute> },
    { path: '/attendant', element: <PrivateRoute Group={['integrador']}><ListAttendant /> </PrivateRoute> },
    { path: '/teleatendente', element: <PrivateRoute Group={['integrador']}><ListAttendant /> </PrivateRoute> },
    { path: '/attendant/create', element: <PrivateRoute Group={['integrador']}><CreateAttendant /></PrivateRoute> },
    { path: '/cadastro/teleatendente', element: <PrivateRoute Group={['integrador']}><CreateAttendant /></PrivateRoute> },
    { path: '/scheduling', element: <PrivateRoute Group={['integrador']}><ListScheduling /></PrivateRoute> },
    { path: '/agendamento', element: <PrivateRoute Group={['integrador']}><ListScheduling /></PrivateRoute> },
    { path: '/attendant/edit/:id', element: <PrivateRoute Group={['integrador']}><EditAttendant /></PrivateRoute> },
    { path: '/editar/teleatendente/:id', element: <PrivateRoute Group={['integrador']}><EditAttendant /></PrivateRoute> },
    { path: '/operator/edit/:id', element: <PrivateRoute Group={['integrador']}><EditOperator /></PrivateRoute> },
    { path: '/editar/operador/:id', element: <PrivateRoute Group={['integrador']}><EditOperator /></PrivateRoute> },

];