import axios from "axios";
import { authFetch } from "../../../services/fetch";

export interface IError {
    readonly type: string;
    readonly title: string;
    readonly details: string;
    readonly status: number;
    readonly instance: string;
    readonly invalid_params: {
        readonly name: string;
        readonly reason: string;
    }[]
}

interface IAddress {
    readonly erro: boolean;
    readonly logradouro: string;
    readonly unidade: string;
    readonly complemento: string;
    readonly localidade: string;
    readonly bairro: string;
    readonly estado: string;
}

export async function createCitizen(data: any) {
    try {
        const response = await authFetch({
            url: '/users/create',
            data: {
                group: 'cidadão',
                ...data
            }
        })
        return {hasError: false, data: response.data}
    } catch (error) {
        console.error('~ createCitizen: ', error);
        return {hasError: true, error: error as IError}
    }
}

export async function getAddressByCep(cep: string): Promise<IAddress> {
    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
    return response.data 
    
}

export async function listCitizenByGroup(group = '', page = 0 , limit = 100, sort=[], filters={}) {
    try {
        const response = await authFetch({
            url:`/users/group-role`,
            method: "GET",
            params: {
                group: group,
                page: page,
                limit: limit,
                filters: JSON.stringify(filters)
            }
        })
        return {hasError: false, data: response.data}
    } catch (error) {
        console.error('~ listCitizenByGroup: ', error);
        return {hasError: true, error: error as IError}
    }
}

export async function updateCitizen(data: any) {
    try {
      const response = await authFetch({
        url: `/users/update/${data?.id}`,
        method: "PATCH",
        data: data,
      });
      return { hasError: false, data: response.data };
    } catch (error) {
      console.error("~ editCitizen: ", error);
      return { hasError: true, error: error as IError };
    }
}

export async function getCitizenById(id: string) {
    try {
      const response = await authFetch({
        url: `/users/${id}`,
        method: "GET"      
    });
      return { hasError: false, data: response.data };
    } catch (error) {
      console.error("~ editCitizen: ", error);
      return { hasError: true, error: error as IError };
    }
}