import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import notify from "../../../../utils/notify";
import InputField from "../../../../components/Field";
import ReasonsCancelSelect from "./ReasonsCancelSelect";
import CancelButton from "../../../../components/Buttons/CancelButton";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import { useCallback, useEffect, useState } from "react";
import { sanitizerName } from "../../../../utils/StringsManipulationsUtil";
import GridDataDisplay from "../../../../components/DataDisplay/GridDataDisplay";
import { cancelScheduling, getStatusCancelScheduling } from "../../services/SchedulingServices";
import SimpleLoadingFeedback from "../../../../core/Feedbacks/SimpleLoadingFeedback";

interface ICancelSchedulingModal {
  readonly open: boolean;
  readonly hasClosed: ()=> void;
  scheduling: any;
}


export default function CancelSchedulingModal({ open=false, scheduling, hasClosed= ()=>{}}: ICancelSchedulingModal) {
  const [status, setStatus] = useState<any>(null);
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusError, setStatusError] = useState(false);

  const navigate = useNavigate();

  const handleConfirmClick = async() => {
    setCommentError(false)
    setStatusError(false)
    console.log(scheduling?.id, status)
    if (comment?.length > 0 && status !== undefined && status !== null ) {
    const response = await cancelScheduling(scheduling?.id, comment, status?.id)
     
      if(!response.hasError){
        notify({message: 'Agendamento cancelado com sucesso!', type: 'success'})
        setTimeout(() => hasClosed(), 1000);
      }else{
        notify({message: 'Ocorreu um erro ao cancelar o agendamento!', type: 'error'})
      }
    } else {
        if(comment?.length <= 0){
          setCommentError(true);
        }
        if(status === '' || status === null || status === undefined){
          setStatusError(true)
        }
     }
  };

  const setStatusToCancel = async () =>{
    setLoading(true)
    const response = await getStatusCancelScheduling()
    if(!response.hasError){
      setStatusData(response?.data.map((indice:any)=>({id: indice.id, label: indice.reason})))
      setLoading(false)
    }
  }

  useEffect(()=>{
    if(open){
      setStatusToCancel()
    }
  }, [open])

  const loadReasonsCancelSelect = useCallback(()=>{
    if(statusData.length > 0){

      return <Grid paddingTop={2}>
        <ReasonsCancelSelect
          onStatusChange={(newStatus) => setStatus(newStatus)}
          status={status}
          options={statusData}
          error={statusError}
          />
      </Grid>
    }else{
      return <></>
    }
    
  }, [statusData, statusError, status])

  return (
    <Dialog
      open={open}
      onClose={hasClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
      fullWidth
    
    >
      <DialogTitle id="alert-dialog-title">
        Cancelar Agendamento
     
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <GridDataDisplay titulo='Cidadão' info={sanitizerName(scheduling?.name)} bottom={0}/>
          <GridDataDisplay titulo="Tipo Serviço" info={scheduling?.type_service}  bottom={0}/>
          <GridDataDisplay titulo='Horário' info={scheduling?.hour}/>
        </Grid>
        <DialogContentText id="alert-dialog-description">
          Você está cancelando o registro desse agendamento. Selecione o motivo abaixo:
        </DialogContentText>
        
          {loadReasonsCancelSelect()}
          <Grid paddingTop={2}>
            <InputField
              value={comment}
              label="Comentário"
              helperText={commentError && 'É obrigatório colocar o motivo do agendamento não ter sido realizado.'}
              rows={5}
              error={commentError}
              multiline
              required
              onChange={(event: { target: { value: string } }) => {
                event.target.value.length && setCommentError(false)
                setComment(event.target.value);
              }}
            />
          </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <CancelButton onCancelClick={hasClosed} />
        </Grid>

        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton onConfirmClick={handleConfirmClick} />
        </Grid>
      </DialogActions>
      <SimpleLoadingFeedback open={loading} text="Aguarde. Carregando Dados..."/>
    </Dialog>
  );
}
