import React from "react";
import Grid from "@mui/material/Grid2";

import ControlStepForm from "../Steps/ControlStepForm";
import { useAccreditedForm } from "../../../contexts/AccreditedFormContext";
import SumaryAccreditedGrid from "./SumaryAccreditedGrid";
import LabelSumary from "./LabelSumary";
import LabelTitleSumary from "./LabelTitleSumary";
interface IKeywordRegisterTab {
  readonly confirmAction?: () => void;
}

export default function SumaryAccreditedForm({
  confirmAction = () => {},
}: IKeywordRegisterTab) {

  const {fields, sendToApi} = useAccreditedForm()

  return (
      <Grid container spacing={2} sx={{ width: "100%" }}>
         <Grid size={{xs:12}}>
            <LabelTitleSumary>Dados da Empresa Credenciada</LabelTitleSumary>
          </Grid> 
         <Grid size={{xs:12, md:6}}>
          <SumaryAccreditedGrid  label= 'CNPJ' info={fields?.cnpj}/>
         </Grid>
         <Grid size={{xs:12, md:6}}>
          <SumaryAccreditedGrid  label= 'Razão Social' info={fields?.corporate_name}/>
         </Grid>
         <Grid size={{xs:12, md:6}}>
          <SumaryAccreditedGrid  label= 'Nome Fantasia' info={fields?.trade_name}/>
          </Grid>
        
        
         <Grid size={{xs:12, md:6}}>
          <SumaryAccreditedGrid  label= 'CEP' info={fields?.zip_code}/>
         </Grid>
         <Grid size={{xs:12, md:6}}>
          <SumaryAccreditedGrid  label= 'Rua' info={fields?.street}/>
         </Grid>
         <Grid size={{xs:12, md:6}}>
          <SumaryAccreditedGrid  label= 'Número' info={fields?.number}/>
         </Grid>
         <Grid size={{xs:12, md:6}}>
          <SumaryAccreditedGrid  label= 'Complemento' info={fields?.complement}/>
         </Grid>
         <Grid size={{xs:12, md:6}}>
          <SumaryAccreditedGrid  label= 'Cidade' info={fields?.city}/>
         </Grid>
         <Grid size={{xs:12, md:6}}>
          <SumaryAccreditedGrid  label= 'Estado' info={fields?.state}/>
         </Grid>
         <Grid size={{xs:12}}>
            <LabelTitleSumary>Dados do Usuário Administrador</LabelTitleSumary>
          </Grid>
          <Grid size={{xs:12, md:6}}>
          <SumaryAccreditedGrid  label= 'CPF' info={fields?.admin_partner_cpf}/>
         </Grid>
         <Grid size={{xs:12, md:6}}>
          <SumaryAccreditedGrid  label= 'Nome do Usuário Administrador' info={fields?.admin_partner_name}/>
         </Grid>
         <Grid size={{xs:12, md:6}}>
          <SumaryAccreditedGrid  label= 'Email' info={fields?.email}/>
         </Grid>
        <ControlStepForm canMoveForward={true} onFinalize={sendToApi}/>
      </Grid>
  );
}
