import Grid from '@mui/material/Grid2';
import MainLayout from '../../../core/Layout/MainLayout';
import CardTracks from '../components/Card/CardTracks';
import ContainerLayout from '../../../core/Layout/ContainerLayout';
import TitleLayout from '../../../core/Layout/TitleLayout';
import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import {useAuth} from 'react-oidc-context';
import { listTrackerByUserId } from '../services';
import { useSocket } from '../../../core/Contexts/SocketContext';


export default function TracksPage() {
  
  const [idUser, setIdUser] = React.useState<any>(null);
  const [tracks, setTracks] = React.useState<Array<any>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const auth = useAuth();
  const {socket } = useSocket()


  const getAllTrack = async () => {
    const response = await listTrackerByUserId(idUser)
    if(!response.hasError){
      const listTrack = response.response?.data;
      setTracks(listTrack)
      setIsLoading(false)
    }
    else{
      setIsLoading(false)
      console.log('Erro listagem track')
    }
  } 

  const updateTrack = (track: any) => {
    if(tracks.length > 0){
      const updatedList = tracks.map(trackList => {
        if(trackList.trackCode === track.code){
          return {
            ...trackList,
            recentUpdate: track.time,
          };
        }else{
          return trackList
        }
      })
      setTracks(updatedList)
    }
  }

  useEffect( ()=> {
    if(idUser !== null && idUser !== undefined){
      getAllTrack()
    }
  }, [idUser])

  useEffect(()=>{
    setIsLoading(true)
    if(auth.isAuthenticated){
      const ideUserKC = auth.user?.profile.sub as any;
      if(ideUserKC !== undefined){
        setIdUser(ideUserKC)
      }else{
        setIsLoading(false)
      }
    }else{
      setIsLoading(false)

    }
 }, [auth.isAuthenticated])

 useEffect(()=> {
  if(socket !== null) {
    socket.on("TRACKER_LOCATION", updateTrack);
  }

  return ()=> {
    if (socket !== null )
      socket.off("TRACKER_LOCATION", updateTrack);
  }
}, [socket,tracks])

  return (
    <MainLayout>
      <TitleLayout>
        Lista de Rastreadores
      </TitleLayout>
      <ContainerLayout loading={isLoading}>
        {tracks.length > 0? 
        (
          <Grid container spacing={3}>
            {tracks.map((track):any =>
              <Grid size={{xs: 12, sm:6, md: 6, lg: 4}}>
                <CardTracks 
                  trackCode={track?.trackCode} 
                  plate={track?.plate} 
                  status={track?.status} 
                  keyword={track?.keyword} 
                  trackday={track?.recentUpdate}
                  />
              </Grid>
            )}
          </Grid>
        ):(
          <Grid container alignItems={"center"} justifyContent={"center"} sx={{height: '80vh'}}>
            <Typography align="center" sx={{ color: '#a63939', mb: 1 , fontSize: '1rem'}}>
            Você ainda não tem nenhum rastreador cadastrado. Realize o agendamento junto ao DETRAN e vá ao local indicado com seu veículo para realizar a instalação do rastreador.
            </Typography>
          </Grid>
        )}
      </ContainerLayout>
      
    </MainLayout>
 
 
  );
}
//863829075937217