import { authFetch } from "../../../services/fetch";

export async function getTrackersIndicators() {
    try {
      const response = await authFetch({
        url: `/tracker/count-installed`,
        method: "GET"      
    });
      return { hasError: false, data: response.data };
    } catch (error) {
      console.error("~ getTrackersIndicators: ", error);
      return { hasError: true, error: error as never };
    }
}