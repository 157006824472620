import React, {  useState } from 'react';
import Grid from '@mui/material/Grid2';

import { useForm } from 'react-hook-form';
import SimpleInputFieldForm from '../../../../components/Form/components/Fields/SimpleInputFieldForm';
import EmailFieldForm from '../../../../components/Form/components/Fields/EmailFieldForm';
import CPFFieldForm from '../../../../components/Form/components/Fields/CPFFieldForm';
import { useAccreditedStep } from '../../contexts/AccreditedStepContext';
import ControlStepForm from './Steps/ControlStepForm';
import { useAccreditedForm } from '../../contexts/AccreditedFormContext';


export default function AdminDataAccreditedForm() {
  const {handleNext} = useAccreditedStep();
  const {mergeFieldsInState, fields} = useAccreditedForm()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {  isValid },
    
  } = useForm({ mode: "all" });

  const submitForm = async (event:any) => {
    try {
      
     if (isValid){
       await mergeFieldsInState(event)
       handleNext()
     }

    } catch (e) {
      console.log(e, 'submit')
    }
   }




  return (
    <Grid container component="form" sx={{padding: 2, width: '100%'}} spacing={2} onSubmit={handleSubmit(submitForm)}>
      <Grid size={{xs:12, sm: 6, md:3, lg:3 ,xl: 2.5}}>
        <SimpleInputFieldForm 
          name='admin_partner_name' 
          label='Nome do Usuário Administrador' 
          required={true}  
          messageError='Nome é obrigatório' 
          value= {fields?.admin_partner_name} 
          id='nome_input'
          register={register} 
          control={control}
          setValue={setValue}

        />
      </Grid>
      <Grid size={{xs:12, sm: 6, md:3, lg:3 ,xl: 2.5}}>
        <EmailFieldForm 
          name='email' 
          label='Email Admistrador' 
          required={true}  
          value= {fields?.email} 
          id='email_input'
          register={register} 
          control={control}
          setValue={setValue}

        />
      </Grid>
      <Grid size={{xs:12, sm: 6, md:3, lg:3 ,xl: 2}}>
        <CPFFieldForm 
          name='admin_partner_cpf' 
          label='CPF' 
          required={true}  
          value= {fields?.admin_partner_cpf} 
          id='cpf_input'
          register={register} 
          control={control}
          setValue={setValue}
        />

      </Grid>

    
      <ControlStepForm canMoveForward={isValid} />
    
    </Grid>
           

  );
}

