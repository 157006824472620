import Typography  from "@mui/material/Typography";
import React, { ReactNode } from "react";

type LayoutProps = {
    readonly children: ReactNode;
}

export default function LabelTitleSumary({ children}: LayoutProps) {
    return (
        
    <Typography component='p' sx={{fontWeight: 700, color: 'text.secondary', pt: 1}}>
       <u>{children}</u> 
    </Typography>
    )
}
