import { useState } from "react";
import Grid from "@mui/material/Grid2";
import FilterAutocompleteCitizen from "../../../../components/Filters/FilterAutocompleteCitizen";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import InputField from "../../../../components/Field";
import { State, useHookstate } from "@hookstate/core";
import { Dayjs } from "dayjs";
import { IDataTable } from "../../../../components/CrudIndex";

export interface ICitizen {
  readonly id: string;
  readonly name: string;
  readonly cpf: string;
  readonly phone: string;
  readonly birthDate: string | Dayjs;
  readonly email: string;
  readonly groups: {
    readonly name: string
  }[];
  readonly street: string;
  readonly neighborhood: string;
  readonly state: string;
  readonly number: string;
  readonly complement: string;
  readonly city: string;
  readonly zip_code: string;
  readonly vehiclePurposeId: number;
}
interface ISearchCitizenTab {
  readonly citizenId: State<string | null>;
  readonly value: State<number>;
  readonly citizenObj: State<ICitizen | null>;
}

export default function SearchCitizenTab({
  citizenId,
  value,
  citizenObj
}: ISearchCitizenTab) {
  const [error, setError] = useState(false);

  const handleConfirmClick = () => {
    if (citizenId.get()) {
      value.set(value.get() + 1);
    } else {
      setError(true);
    }
  };

  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <FilterAutocompleteCitizen
          citizenId={citizenId.get() ?? undefined}
          onCitizenChange={(value) => {
            citizenId.set(value?.id ?? null);
            citizenObj.set(value)
            value !== null && setError(false);
          }}
          citizenObj={citizenObj.get({noproxy: true})}
          error={error}
          required
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField label={'Nome'} value={citizenObj.get({noproxy: true}) ? citizenObj.get({noproxy: true})?.name : ''} disabled />
      </Grid>
      <Grid
        container
        size={12}
        sx={{ gap: 1 }}
        display={"flex"}
        justifyContent="flex-end"
        paddingTop={2}
      >
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton stepMode onConfirmClick={handleConfirmClick} />
        </Grid>
      </Grid>
    </Grid>
  );
}
