import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import logo_emergencia from '~/assets/imgs/logo_emergencia.png'
import { Box, Grid2, Typography } from '@mui/material';

export interface LoadingFeedbackProps {
  readonly open: boolean;
  text?:string;
 }

export default function TrackSplashFeedback({open =false, text = ''}: LoadingFeedbackProps) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: 'rgb(0 0 0 / 70%)' }}
      open={open}
    >
      <Grid2 container justifyContent="center" alignItems="center" direction="column" sx={{}}>
         <Typography component='p' variant="h5" sx={{}}>
            {text}
          </Typography>
        <CircularProgress color="primary" size={50}/>
      </Grid2>
    </Backdrop>
  )
}
