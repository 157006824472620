import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import InputField from '../../../../components/Field';

interface CitizenProps {
    readonly onStatusChange: (value: string) => void;
    readonly status?: string | null;
    readonly disabled?: boolean
    //readonly required?: boolean
    readonly error?: boolean
    readonly options?: Array<any>;

}

export default function ReasonsCancelSelect({ onStatusChange, status = null, error = true, options =[] }: CitizenProps) {
    const [value, setValue] = useState<any>(status);

    useEffect(() => {
        setValue(status)
    }, [status])


    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-status'
                fullWidth
                options={options}
                value={value}
                noOptionsText={'Sem opção'}
                size='small'
                isOptionEqualToValue={(option, value) => option.id === value}
                getOptionLabel={(option) => option.label}
                onChange={(_, status) => {
                    setValue(status)
                    onStatusChange(status)
                }}
                renderInput={(params) => (
                    <InputField
                        {...params}
                        label={'Motivo do Cancelamento'}
                        required
                        error={error}
                        helperText={error?'Selecione um Motivo' : ''}
                    />
                )}
              
            />
        </FormControl>
    )
}
