import React from "react";
import Grid from "@mui/material/Grid2";
import LabelTextBoldDataDisplay from "./Texts/LabelTextBoldDataDisplay";
import LabelTextDataDisplay from "./Texts/LabelTextDataDisplay";


export default function GridDataDisplay({titulo= '', info='', bottom=1}) {
  return (
    <Grid container sx={{pb:bottom}}>
      <LabelTextBoldDataDisplay>
        {titulo}:
      </LabelTextBoldDataDisplay>
      <LabelTextDataDisplay>
        { info !== '' && info !== null && info !== undefined? info : 'Não informado'}
      </LabelTextDataDisplay>
    </Grid>
  );
}
