import React, { useState } from "react";
import Grid from "@mui/material/Grid2";

import { useNavigate } from "react-router-dom";
import {useAuth} from 'react-oidc-context';
import { useForm } from 'react-hook-form';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PrimaryResponsiveButton from "../../../../../components/Buttons/PrimaryResponsiveButton";
import SimpleInputFieldForm from "../../../../../components/Form/components/Fields/SimpleInputFieldForm";
import SimpleTextareaForm from "../../../../../components/Form/components/Fields/SimpleTextareaForm";
import { createOccurrence } from "../../../services";
import notify from "../../../../../utils/notify";
import SimpleDialogFeedback from "../../../../../core/Feedbacks/SimpleDialogFeedback";
import ConfirmDialogFeedback from "../../../../../core/Feedbacks/ConfirmDialogFeedback";

export default function RegisterTrackTeleservicesForm({plate= '', keyword=''}) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {isValid},
    getValues
  } = useForm({ mode: "all", reValidateMode:'onBlur'});

  const [processing, setProcessing] = useState<boolean>(false);


  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState<boolean>(false);
  const [openConfirmRunning, setOpenConfirmRunning] = React.useState<boolean>(false);


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
      noSsr: true
  });


  const submitForm = async () => {

    setProcessing(true);
    const body = getValues()
    if(isValid){

      const response  = await createOccurrence(plate, keyword,body.ocurrence,body.coment ) as any
      //   console.log(response, 'response')
        if(!response?.hasError){
        
          setOpenConfirmModal(true)
        }else{
          notify({ message: "Não foi possível gerar a ocorrência.", type: "error" });

        }
        setOpenConfirmRunning(false)
      }

    setShowMessage(true)
    setProcessing(false);
  };

  const clearFileds = () => {
    setValue('ocurrence', '')
    setValue('coment', '')
  }

  const onConfirm = () =>{
    
    navigate("/")
  }


  return (    
    <Grid container component="form" sx={{padding:0}} spacing={2} noValidate>
        <Grid size={{xs:12,md:3}}>
          <SimpleInputFieldForm
           label="Ocorrência"
            register={register}
            control={control}
            name='ocurrence'
            value={getValues('ocurrence')}
            setValue={setValue}
            id='ocurrence'
            required={true}
          />
        </Grid>
        <Grid size={{xs:12,md:3}}>
          <SimpleTextareaForm
            label="Comentário"
            name="coment"
            register={register}
            control={control}
            messageError={"O campo não pode ser vazio"}
            setValue={setValue}
            id='coment'
            value={getValues('coment')}
            //required={true}
            
          />
        </Grid>
        <Grid size={{xs:12,md:4}}>
          <Grid container spacing={2} sx={{ height: !isMobile?'100px': 'auto'}} alignItems={'center'} justifyContent={ !isMobile? 'flex-start': 'center'}>

            <PrimaryResponsiveButton  disabled={processing} onClick={()=>setOpenConfirmRunning(true)}> 
              Ativar Rastreamento
            </PrimaryResponsiveButton>
          </Grid>
        </Grid>

        <SimpleDialogFeedback 
          open={openConfirmModal} 
          title="Rastreamento"
          onCloseDialog={onConfirm}
          onConfirm={onConfirm}
          content={'Rastreamento Ativado'} 
        />
        <ConfirmDialogFeedback 
          handleAgree={submitForm}
          open={openConfirmRunning}
          handleClose={()=>{setOpenConfirmRunning(false)}}
          title="Ativar Rastreamento"
          content="Ao ativar o rastreamento desse veículo, ela será perseguido em tempo real pela polícia. Você tem certeza que deseja realizar essa ação?"
          AgreeLabel="Ativar"
        />
    </Grid>
  );
}

