import Leaflet from "leaflet";
import { Marker } from "react-leaflet";
import { useTheme } from "@mui/material/styles";


type MapMarkerAnkletProps = {
  lat: number;
  lng: number;
};

export default function MotoMarker({ lat, lng }: MapMarkerAnkletProps) {
  const theme = useTheme();

  const svgIcon = `<svg width="34" height="70" viewBox="0 0 94 130" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M73.4036 83.1542L73.3992 83.1572C65.7033 88.4117 60.0046 96.0109 57.1968 104.768C57.1966 104.769 57.1964 104.769 57.1963 104.77L49.7062 127.522L49.7049 127.526C49.5229 128.095 49.1587 128.595 48.6631 128.951C48.1674 129.307 47.5666 129.5 46.9486 129.5C46.3305 129.5 45.7298 129.307 45.2341 128.951C44.7385 128.595 44.3743 128.095 44.1923 127.526L44.1914 127.523L36.7008 104.569C36.7007 104.569 36.7006 104.568 36.7005 104.568C33.8937 95.8352 28.075 88.3277 20.3938 83.1561C13.842 78.6921 8.57927 72.6555 5.11163 65.6293C1.64383 58.6028 0.0879016 50.8228 0.593171 43.0391C1.09844 35.2554 3.64797 27.7293 7.99697 21.1863C12.346 14.6433 18.3482 9.30374 25.4259 5.68357C32.5036 2.06336 40.4175 0.285038 48.4045 0.520719C56.3914 0.756401 64.1808 2.9981 71.0217 7.02886C77.8625 11.0596 83.5235 16.7431 87.4602 23.5303C91.3968 30.3175 93.4766 37.98 93.5 45.7792C93.4995 61.2424 85.5849 75.023 73.4036 83.1542Z" fill="${theme.palette.primary.main}" stroke="black"/>
<path d="M63.6667 45.4286C63.2917 45.4286 62.9167 45.4907 62.5625 45.5321L58.2708 41.2857H63.6667V35.0714L55.9167 38.9243L49.9375 33H40.75V37.1429H48.2292L52.3958 41.2857H44.9167L36.5833 45.4286L32.4167 41.2857H22V45.4286H30.3333C25.7292 45.4286 22 49.1364 22 53.7143C22 58.2921 25.7292 62 30.3333 62C34.9375 62 38.6667 58.2921 38.6667 53.7143L42.8333 57.8571H49.0833L56.3542 45.2214L58.4583 47.3136C56.5625 48.8257 55.3333 51.125 55.3333 53.7143C55.3333 58.2921 59.0625 62 63.6667 62C68.2708 62 72 58.2921 72 53.7143C72 49.1364 68.2708 45.4286 63.6667 45.4286ZM30.3333 57.8571C28.0417 57.8571 26.1667 55.9929 26.1667 53.7143C26.1667 51.4357 28.0417 49.5714 30.3333 49.5714C32.625 49.5714 34.5 51.4357 34.5 53.7143C34.5 55.9929 32.625 57.8571 30.3333 57.8571ZM63.6667 57.8571C61.375 57.8571 59.5 55.9929 59.5 53.7143C59.5 51.4357 61.375 49.5714 63.6667 49.5714C65.9583 49.5714 67.8333 51.4357 67.8333 53.7143C67.8333 55.9929 65.9583 57.8571 63.6667 57.8571Z" fill="#FFF"/>
</svg>`;

  return (
    <Marker
      icon={Leaflet.divIcon({
        className: "custom-svg-icon",
        html: svgIcon,
        iconAnchor: [0, 17],
        popupAnchor: [-5, -40],
        iconSize: [20, 20],
      })}
      eventHandlers={{
        click: () => {
          //open(ankler)
        },
        popupclose: () => {
          //close(null)
        },
      }}
      key={lat}
      position={[lat, lng]}
    />
  );
}
