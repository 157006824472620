import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import CancelButton from "../../../../components/Buttons/CancelButton";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import InputField from "../../../../components/Field";
import { State, useHookstate } from "@hookstate/core";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { InputAdornment, IconButton, Card, Typography } from "@mui/material";
import TermOfUse from "../UseTerm";

interface IKeywordRegisterTab {
  readonly keyword: State<string>;
  readonly confirmKeyword: State<string>;
  readonly value: State<number>;
}

export default function KeywordRegisterTab({
  keyword,
  value,
  confirmKeyword,
}: IKeywordRegisterTab) {
  const keywordError = useHookstate(false);
  const confirmKeywordError = useHookstate(false);
  const showKeyword = useHookstate(false);
  const showConfirmKeyword = useHookstate(false);

  const handleClickShowKeyword = () => {
    showKeyword.set(!showKeyword.get());
  };

  const handleClickShowConfirmKeyword = () => {
    showConfirmKeyword.set(!showConfirmKeyword.get());
  };

  const handleConfirmClick = () => {
    if (keyword.get().length < 4) {
      keywordError.set(true);
    }

    if (!keywordError.get() && !confirmKeywordError.get()) {
      value.set(value.get() + 1);
    }
  };

  useEffect(() => {
    if (keyword.get() === confirmKeyword.get()) {
      confirmKeywordError.set(false);
    } else {
      confirmKeywordError.set(true);
    }
  }, [keyword.get()]);

  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          label="Chave de Rastreamento"
          helperText={
            keywordError.get()
              ? "Registro obrigatório de chave de rastreamento com, no mínimo, 4 caracteres."
              : "Você usará essa chave de rastreamento quando precisar localizar o veículo. Por isso, registre uma chave de rastreamento de fácil recordação com, no mínimo, 4 caracteres."
          }
          value={keyword.get()}
          error={keywordError.get()}
          onChange={(event: { target: { value: string } }) => {
            keyword.set(event.target.value);
            keyword.get().length && keywordError.set(false);
          }}
          type={showKeyword.get() ? "text" : "password"}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowKeyword}
                    edge="end"
                  >
                    {showKeyword.get() ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          label="Confirme a chave de rastreamento"
          helperText={
            confirmKeywordError.get()
              ? !confirmKeyword.get().length
                ? "Confirmação de chave de rastreamento obrigatória."
                : "Chaves de rastreamento não coincidem."
              : ""
          }
          value={confirmKeyword.get()}
          error={confirmKeywordError.get()}
          onChange={(event: { target: { value: string } }) => {
            confirmKeyword.set(event.target.value);
            confirmKeyword.get() === keyword.get()
              ? confirmKeywordError.set(false)
              : confirmKeywordError.set(true);
          }}
          type={showConfirmKeyword.get() ? "text" : "password"}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmKeyword}
                    edge="end"
                  >
                    {showConfirmKeyword.get() ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      </Grid>
      <Grid size={12} marginTop={2} sx={{ height: "40%" }}>
        <Card
          variant="outlined"
          sx={{
            padding: 2,
            overflowY: "auto",
            maxHeight: "60%",
          }}
        >
          <TermOfUse />
        </Card>

        <Typography
          variant="body2"
          fontWeight={"bold"}
          paddingY={1}
          textAlign={"justify"}
        >
          Ao confirmar o cadastro, você declara que leu e aceitou os termos de
          autorização para acesso ao rastreamento veicular.
        </Typography>
      </Grid>
      <Grid
        container
        size={12}
        sx={{ gap: 1 }}
        display={"flex"}
        justifyContent="flex-end"
      >
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <CancelButton
            stepMode
            onCancelClick={() => value.set(value.get() - 1)}
          />
        </Grid>

        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton stepMode onConfirmClick={handleConfirmClick} />
        </Grid>
      </Grid>
    </Grid>
  );
}
