import { PrimaryPlatformProps } from "..";
import { ICitizen } from "../../../../features/Tracks/components/SearchCitizenTab";
import { authFetch } from "../../../../services/fetch";

export async function getVehiclePurpose(): Promise< PrimaryPlatformProps[]> {
    try {
      const response = await authFetch({
        url: '/vehicle-purpose',
        method: 'GET',
      })
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }