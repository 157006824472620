import React from "react";

import Chip from '@mui/material/Chip';

import dayjs from "dayjs";

export default function LabelLastUpdadeTracks({ 
    trackday= null, 
}: any) {
    const getlabelDateLastUpdate = () => {
        if ( trackday !== null && trackday !== undefined  && trackday !== ''){
            const today = dayjs()
            const diff = today.diff(trackday, 'minute')

            if(diff <= 15){
                //console.log(diff , 'diffgetlabelDateLastUpdate')
                return <Chip 
                    size="small" 
                    label={ dayjs(trackday).format("DD/MM/YYYY HH:mm:ss")} 
                    sx={{ bgcolor: '#baffd0'}} />
            }    
            else if(diff > 15 && diff <= 360)
                return <Chip 
                        size="small" 
                        label={ dayjs(trackday).format("DD/MM/YYYY HH:mm:ss")}  
                        sx={{ bgcolor: '#f7fb7b'}}   
                        />
            else if(diff > 360 && diff <= 5760)
                return    <Chip 
                            size="small" 
                            label={ dayjs(trackday).format("DD/MM/YYYY HH:mm:ss")} 
                            sx={{ bgcolor: '#f3c26e'}}   
                            />    
            else if(diff > 5760)
                return <Chip 
                    size="small" 
                    label={ dayjs(trackday).format("DD/MM/YYYY HH:mm:ss")} 
                    sx={{ bgcolor: '#ffa89b', color: '#121212'}}    />
         }else{
            return ( <Chip size="small" label={"Sem Dados Recebidos"} color="error" variant="outlined"/>)
         }
                         
    }
    return (
        <>
            {trackday !== '' && (
                 getlabelDateLastUpdate()
            )}
        </>                              
    )
}
