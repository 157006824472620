import Grid from "@mui/material/Grid2";
import { Tooltip, Typography } from "@mui/material";
import CrudIndex, { IDataTable } from "../../../../components/CrudIndex";
import {
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../../core/Layout/MainLayout";
import TitleLayout from "../../../../core/Layout/TitleLayout";
import { useHookstate } from "@hookstate/core";
import { deleteUser, getUsers } from "../../services";
import { useEffect } from "react";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import notify from "../../../../utils/notify";

export default function ListAttendant() {
  const navigate = useNavigate();
  const openDialog = useHookstate(false);
  const columns: GridColDef[] = [
    { field: "name", headerName: "Nome", flex: 1 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "cpf",
      headerName: "CPF",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Telefone",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <Tooltip title={"Editar"}>
          <GridActionsCellItem
            key={0}
            icon={<EditOutlinedIcon />}
            onClick={() => handleEdit(params.row.id)}
            label={"Editar"}
            id="btn-edit-user"
            className="btn-edit-user"
          />
        </Tooltip>,
        <Tooltip title={"Excluir"}>
          <GridActionsCellItem
            key={1}
            icon={<DeleteIcon />}
            onClick={() => handleDelete(params.row.id, params.row?.name)}
            label={"Excluir"}
            id="btn-delete-user"
            className="btn-delete-user"
          />
        </Tooltip>,
      ],
    },
  ];
  const rows = useHookstate<IDataTable[]>([]);
  const loading = useHookstate(false);
  const rowId = useHookstate<string>("");
  const rowName = useHookstate<string>("");

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    loading.set(true);

    return await getUsers()
      .then((entry) => {
        const entryFiltered = entry.filter((item) =>
          item.groups.map((group) => group.name).includes("teleatendente")
        );
        rows.set(entryFiltered);
      })
      .finally(() => loading.set(false));
  }

  function handleDelete(id: string, name: string) {
    openDialog.set(true);
    rowId.set(id);
    rowName.set(name);
  }

  function handleEdit(id: string) {
    navigate(`/editar/teleatendente/${id}`);
  }

  const handleDialogClose = () => {
    openDialog.set(false);
  };

  const handleDialogCloseFunction = () => {
    try {
      deleteUser(rowId.get()).then((response) => {
        notify({
          message: "Teleatendente deletado com sucesso!",
          type: "success",
        });
        openDialog.set(false);
        getData()
      })
    } catch {
      notify({
        message: "Algo deu errado, tente novamente mais tarde!",
        type: "error",
      });
    }
  };

  return (
    <MainLayout>
      <Grid
        container
        size={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          paddingRight: 2,
        }}
      >
        <TitleLayout>Teleatendente</TitleLayout>
        <Grid size={{ xs: 12, sm: 5, md: 3, lg: 2 }} sx={{ display: "flex" }}>
          <ConfirmButton
            label="Adicionar Teleatendente"
            onConfirmClick={() => navigate("/cadastro/teleatendente")}
            sx={{ padding: 2 }}
          />
        </Grid>
      </Grid>
      <CrudIndex
        columns={columns}
        row={rows.get({noproxy: true})}
        rowCount={rows.get().length}
        loading={loading.get()}
      />
      <ConfirmDialog
        handleAgree={handleDialogCloseFunction}
        handleClose={handleDialogClose}
        open={openDialog}
        AgreeLabel="Confirmar"
        title={"Confirmação"}
        moreInformation={
          <Typography>
            Deseja remover o teleatendente {rowName.get()}?
          </Typography>
        }
      />
    </MainLayout>
  );
}
