import { MapContainer, TileLayer} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import MotoMarker from './Markers/MotoMarker';

type MapTraker = {
    lat: number;
    lng: number;
    zoom?: number;
    height?: string;
}

export default function MapApp({lat = 0, lng = 0, zoom = 12, height = '100vh'}: MapTraker) {

    return (
        <MapContainer 
            center={[lat, lng]} 
            zoom={zoom} 
            style={{ padding: 0, width: '100%', height: height}}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MotoMarker lat={lat} lng={lng}/>
            
          
        </MapContainer>
    )
}
