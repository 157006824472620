import React, {useEffect} from 'react';
import TextField from '@mui/material/TextField';
import {
 UseFormGetValues,
 UseFormRegister,
 Controller,
 UseFormSetValue,
} from 'react-hook-form';
import InputField from '../../../Field';

interface CrudTextFieldProps {
 readonly register: UseFormRegister<any>;
 readonly name: string;
 readonly getValues?: UseFormGetValues<any>;
 readonly control?: any;
 readonly label?: string;
 required?: boolean;
 messageError?: string;
 value?: string;
 readonly setValue: UseFormSetValue<any>;
 disabled?: boolean;
 id?: string;
}

export default function SimpleInputFieldForm({
 label,
 register,
 control = {},
 name,
 required = false,
 messageError = '',
 value = '',
 setValue,
 disabled = false,
 id="simple-input"
}: CrudTextFieldProps) {
 useEffect(() => {
  if (value !== undefined && value !== null) {
   setValue(name, value);
  }
 }, [value]);

 return (
  <Controller
   {...register(name, {required: { 
    value: required,
    message: messageError === '' ? 'Campo é obrigatório' : messageError,
  }, })}
   name={name}
   control={control}
   //defaultValue={value}
   render={({field, fieldState: {error}}) => {
    return (
     <InputField
      helperText={error && required ? error.message : null}
      error={required ? !!error : false}
      {...field}
      //value={value}
      label={label}
      name={name}
      disabled={disabled}
      id={id}
      required={required}

     />
    );
   }}
  />
 );
}
