import { Card, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MainLayout from "../../../../core/Layout/MainLayout";
import TitleLayout from "../../../../core/Layout/TitleLayout";
import { useHookstate } from "@hookstate/core";
import ContainerLayout from "../../../../core/Layout/ContainerLayout";
import { useEffect } from "react";
import { getTracksPendingInstallation } from "../../../Licensed/services";
import { ITrack } from "../../interface";
import ServiceCard from "./ServiceCard";
import FilterAutocompleteCompanyBranch from "../../../../components/Filters/FilterAutocompleteCompanyBranch";
import PrimaryResponsiveButton from "../../../../components/Buttons/PrimaryResponsiveButton";
import SecondaryResponsiveButton from "../../../../components/Buttons/SecondaryResponsiveButton";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";

export default function TrackServices() {
  const tracksPending = useHookstate<ITrack[]>([]);
  const loading = useHookstate(false);
  const companyBranchId = useHookstate<number | null>(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loading.set(true);
    const filters = companyBranchId.get()
      ? {
          company_branch_id: companyBranchId.get({ noproxy: true }),
        }
      : {};
      getTracksPendingInstallation(filters)
      .then((response) => tracksPending.set(response))
      .finally(() => loading.set(false));
  
  };

  const clearFilter = () => {
    companyBranchId.set(null)
    loadData()
  }

  return (
    <MainLayout>
      <TitleLayout>Serviços</TitleLayout>
      <ContainerLayout>
        <Grid container display={"flex"} size={12} gap={1}>
          <Grid size={{ xs: 12, sm: 12, md: 3 }}>
            <FilterAutocompleteCompanyBranch
              onChange={(value) => companyBranchId.set(value ?? null)}
              required
              companyBranchId={companyBranchId.get()}
            />
          </Grid>
          <Grid
            size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
            display={"flex"}
            sx={{ justifyContent: "flex-end" }}
          >
            <SecondaryResponsiveButton onClick={() => clearFilter()} fullWidth>
              <DeleteIcon />
              Limpar
            </SecondaryResponsiveButton>
          </Grid>
          <Grid
            size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
            display={"flex"}
            sx={{ justifyContent: "flex-end" }}
          >
            <PrimaryResponsiveButton onClick={() => loadData()} fullWidth>
              <SearchIcon /> Buscar
            </PrimaryResponsiveButton>
          </Grid>
        </Grid>

        <Grid size={12} sx={{ height: "75vh", paddingY: 4 }}>
          {loading.get() ? (
            <Grid
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size="3rem" />
            </Grid>
          ) : tracksPending.get().length ? (
            <>
              <Typography paddingY={2}>
                Clique em um card para registrar o serviço.
              </Typography>
              <Card
                variant="outlined"
                sx={{
                  width: "100%",
                  border: 'hidden',
                  height: "90%",
                  overflow: "auto",
                  paddingRight: 2,
                  marginBottom: 4,
                }}
              >
                <Grid container spacing={2}>
                  {tracksPending.get({ noproxy: true }).map((track, index): any => (
                    <Grid size={{ xs: 12, sm: 12, md: 6, lg: 4 }} key={index}>
                      <ServiceCard
                        user={track.user}
                        plate={track.plate}
                        status={"Pendente"}
                        brand={track.brand}
                        trackChip={track.chipCode}
                        trackCode={track.trackCode}
                        trackId={track.id}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </>
          ) : (
            <Typography
              align="center"
              sx={{ color: "#a63939", fontSize: "1rem" }}
            >
             Não há serviços pendentes.
            </Typography>
          )}
        </Grid>
      </ContainerLayout>
    </MainLayout>
  );
}
