import { Tab, Tabs } from "@mui/material";
import MainLayout from "../../../../core/Layout/MainLayout";
import TitleLayout from "../../../../core/Layout/TitleLayout";
import { useHookstate } from "@hookstate/core";
import dayjs, { Dayjs } from "dayjs";
import TabPanel from "../../../../components/TabPanel";
import FiltersSchedulingTab from "../../components/FiltersSchedulingTab";
import SchedulingTab from "../../components/SchedulingTab";
import ContainerLayout from "../../../../core/Layout/ContainerLayout";
import { useEffect} from "react";
import { Appointment, getAllAppointments } from "../../services/appointment";

export default function ListScheduling() {
  const date = useHookstate<Dayjs>(dayjs());
  const value = useHookstate(0);
  const locale = useHookstate<{ id: number; name: string } | null>(null);
  const appointments = useHookstate<Appointment[]>([]);

  const onHasClosedCancelModal = () => {
    if (locale.get() && date.get()) {
      getAllAppointments({ 
        filters: {
          company_branch_id: locale.get() ? locale.get()?.id : 0,
          datetime: date.get().format('YYYY-MM-DD')
        }
      })
      .then((data) => {
        //console.log('appointments', data);
        appointments.set(data);
      })
    }
  }

  useEffect(() => {

    if (locale.get() && date.get()) {
      getAllAppointments({ 
        filters: {
          company_branch_id: locale.get() ? locale.get()?.id : 0,
          datetime: date.get().format('YYYY-MM-DD')
        }
      })
      .then((data) => {
        //console.log('appointments', data);
        appointments.set(data);
      })
    }
    

  }, [locale, date])

  // const mockData = [
  //   {
  //     hour: "08:00",
  //     type_service: "Instalação",
  //     name: "Alicia Chaves",
  //     cpf: "68060147048",
  //     plate: "AAA2222",
  //     status: "Não realizado",
  //   },
  //   {
  //     hour: "09:00",
  //     type_service: "Reparo",
  //     name: "Alicia Chaves",
  //     cpf: "68060147048",
  //     plate: "AAA1111",
  //     status: "Realizado",
  //   },
  //   {
  //     hour: "10:00",
  //     type_service: "Desinstalação",
  //     name: "Alicia Chaves",
  //     cpf: "68060147048",
  //     plate: "AAA1111",
  //     status: "Cancelado",
  //   },
  //   {
  //     hour: "11:00",
  //     type_service: "Instalação",
  //     name: "Alicia Chaves",
  //     cpf: "68060147048",
  //     plate: "AAA1111",
  //     status: "Em andamento",
  //   },
  //   {
  //     hour: "12:00",
  //     type_service: "Instalação",
  //     name: "Alicia Chaves",
  //     cpf: "68060147048",
  //     plate: "AAA1111",
  //     status: "Agendado",
  //   },
  // ];

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <MainLayout>
      <TitleLayout>Agendamentos</TitleLayout>
      <ContainerLayout minHeight="50%">
        <Tabs
          value={value.get()}
          aria-label="basic tabs example"
          onChange={(event: React.SyntheticEvent, newTab) => value.set(newTab)}
        >
          <Tab label="Dia e local" {...tabProps(0)} />
          <Tab label="Agenda" {...tabProps(1)} />
        </Tabs>
        <TabPanel value={value.get()} index={0}>
          <FiltersSchedulingTab date={date} locale={locale} value={value} />
        </TabPanel>
        <TabPanel value={value.get()} index={1}>
          <SchedulingTab
            date={date}
            locale={locale}
            value={value}
            data={appointments.get()}
            hasClosedCancelModal={onHasClosedCancelModal}
          />
        </TabPanel>
      </ContainerLayout>
    </MainLayout>
  );
}
