import React, { useEffect, useState } from "react";
import { Autocomplete, FormControl } from "@mui/material";
import InputField from "../../Field";
import { getCitizenByCPF } from "./services";
import { ICitizen } from "../../../features/Tracks/components/SearchCitizenTab";
import { ImmutableObject, useHookstate } from "@hookstate/core";

interface CitizenProps {
  readonly onCitizenChange: (value: ImmutableObject<ICitizen> | null) => void;
  readonly citizenId?: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly error?: boolean;
  readonly citizenObj?: ImmutableObject<ICitizen> | null;
}

export default function FilterAutocompleteCitizen({
  onCitizenChange,
  citizenId,
  required = false,
  error = false,
  citizenObj,
}: CitizenProps) {
  const [options, setOptions] = useState<ImmutableObject<ICitizen[]>>([]);
  const [value, setValue] = useState<ImmutableObject<ICitizen> | null>(null);
  const loading = useHookstate(false);

  const handleInputChange = (cpf: string) => {
    if (cpf.length >= 3) {
      loading.set(true);
      getCitizenByCPF(cpf)
        .then((citizen) => {
          const citizenFiltered = citizen.filter(
            (item) =>
              item.groups.map((group) => group.name).includes("cidadão") &&
              !!item.cpf
          );
          setOptions(citizenFiltered);
          setValue(null);
        })
        .finally(() => loading.set(false));
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    if (!citizenObj) {
      setValue(null);
      setOptions([])
    } else {
      setOptions([citizenObj])
      setValue(citizenObj)
    }
  }, [citizenObj]);

  return (
    <FormControl fullWidth size="small">
      <Autocomplete
        id="filter-citizen"
        fullWidth
        options={options}
        value={value}
        noOptionsText={"Sem opção"}
        size="small"
        isOptionEqualToValue={(option, value) => option.id === citizenId}
        getOptionLabel={(option) => option.cpf}
        loading={loading.get()}
        loadingText="Pesquisando..."
        onChange={(_, citizen) => {
          setValue(citizen);
          onCitizenChange(citizen);
        }}
        renderInput={(params) => (
          <InputField
            {...params}
            label={"CPF"}
            placeholder='Digite 3 dígitos para pesquisar um CPF'
            required={required}
            onChange={(event: { target: { value: string; }; }) => handleInputChange(event.target.value)}
            error={error}
            helperText={
              error && "Campo obrigatório."
            }
          />
        )}
      />
    </FormControl>
  );
}
