import { Typography } from "@mui/material";
import InputField from "../../../../components/Field";
import SelectDate from "../../../../components/Form/components/SelectDate";
import { removeMask } from "../../../../utils/stringsManipulations";
import Grid from "@mui/material/Grid2";
import { State } from "@hookstate/core";
import InputMask from "react-input-mask";
import { Dayjs } from "dayjs";

interface PersonalDataRegisterProps {
    readonly fullName: State<string>;
    readonly cpf: State<string>;
    readonly phone: State<string>;
    readonly email: State<string>;
    readonly birthDate: State<Dayjs | null>;
}

export default function PersonalDataRegister({fullName, cpf, phone, email, birthDate}:PersonalDataRegisterProps) {
  return (
    <>
      <Typography sx={{ width: "100%" }} variant="h6" fontWeight={"bold"}>
        Dados Pessoais
      </Typography>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          label="Nome Completo"
          value={fullName.get()}
          onChange={(value: { target: { value: string } }) =>
            fullName.set(value.target.value)
          }
          required
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputMask
          mask={`999.999.999-99`}
          maskChar="_"
          value={cpf.get()}
          required
          onChange={(event: { target: { value: string } }) =>
            cpf.set(removeMask(event.target.value))
          }
        >
          <InputField label="CPF" required />
        </InputMask>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputMask
          mask={`(99) 99999-9999`}
          maskChar="_"
          value={phone.get()}
          required
          onChange={(event: { target: { value: string } }) =>
            phone.set(removeMask(event.target.value))
          }
        >
          <InputField label="Telefone" required />
        </InputMask>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          value={email.get()}
          label="Email"
          onChange={(value: { target: { value: string } }) =>
            email.set(value.target.value.trim())
          }
          required
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <SelectDate
          name="birthDate"
          onChange={(value) => birthDate.set(value)}
          required
          value={birthDate.get()}
          label="Data de Nascimento"
        />
      </Grid>
    </>
  );
}
