import React from "react";
import Grid2 from "@mui/material/Grid2";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import UserAvatar from "./UserAvatar";
import Menu from "./Menu";
import { Divider } from "@mui/material";

export default function MobileMenu() {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = () => {
    handleDrawerClose();
  };



  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                mr: 2,
              },
              //open && { display: 'none' },
            ]}
          >
            <MenuIcon />
          </IconButton>
          <Grid2 container sx={{  width: '80%'}} justifyContent="center">
            <img src='/logoMotoSeguraBranca120x41.png' />
          </Grid2>
        </Toolbar>
      </AppBar>
      <Drawer open={open}>
        <Grid2 container sx={{ mt: 2, mb: 2 }} justifyContent="space-between">
          <Grid2 container sx={{  pl:1, width:'60%' }} justifyContent={"flex-end"}>
            <img src='/logoMotoSeguraIcone54x51.png' />
          </Grid2>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
        </Grid2>
        
      <Divider/>
        <UserAvatar />
         <Menu onMenuClick={handleMenuClick}/>   
      </Drawer>
    </>
  );
}
