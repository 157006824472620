import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';

import MainLayout from '../../../core/Layout/MainLayout';
import ContainerLayout from '../../../core/Layout/ContainerLayout';

import TitleLayout from '../../../core/Layout/TitleLayout';
import {BarChart} from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts';
import { listAllTracker } from '../../Tracks/services';
import dayjs from 'dayjs';
import AbsoluteNumbersCards from '../../Dashboard/components/AbsoluteNumbersCards';
import SubtitleLayout from '../../../core/Layout/SubtitleLayout';
import { getTrackersIndicators } from '../services/PanelService';
const label = [1,2,3]
const data = [ 16,36,39]

export default function PanelPage() {
  const [isLoading, setIsLoading] =useState<boolean>(true);
  const [indicators, setIndicators] = useState<any>({
    totalInstalledCount: 0,
    recentUpdateLess6Hours: 0,
    recentUpdateLess4Days: 0,
    recentUpdateMore4Days: 0,
    recentUpdateLess15Mins: 0
});
  const statusColor = {
    0: "black",
    1: "#baffd0",
    2: "#f7fb7b",
    3: "#f3c26e",
    4: "#ffa89b",
  };

  const getAllTrackerIndicator = async () => {
    setIsLoading(true)
    const response = await getTrackersIndicators()
    if(!response.hasError){
     
      setIndicators(response?.data)
      setIsLoading(false)
    }
    else{
      setIsLoading(false)
      console.log('Erro listagem track')
    }
  } 


  useEffect(()=>{
    getAllTrackerIndicator()
  }, [])



  return (
    <MainLayout>
        <TitleLayout >
          Relatórios
        </TitleLayout>
        <ContainerLayout loading={isLoading}>
          <SubtitleLayout sx={{fontWeight: 'bold'}}>Totalizador</SubtitleLayout>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          //columns={{ xs: 2, sm: 4, md: 10 }}
        >
          <AbsoluteNumbersCards
            color={statusColor[0]}
            name="Rastreadores Instalados"
            value={indicators?.totalInstalledCount}
          />
        </Grid>
        <SubtitleLayout sx={{fontWeight: 'bold'}}>Status por último envio de localização</SubtitleLayout>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          //columns={{ xs: 2, sm: 4, md: 10 }}
        >
          <AbsoluteNumbersCards
            color={statusColor[1]}
            name="há menos de 15 min"
            value={indicators?.recentUpdateLess15Mins}
          />
          <AbsoluteNumbersCards
            color={statusColor[2]}
            name="há menos de 6 horas"
            value={indicators?.recentUpdateLess6Hours}
          />
          <AbsoluteNumbersCards
            color={statusColor[3]}
            name="há menos de 4 dias"
            value={indicators?.recentUpdateLess4Days}
          />
          <AbsoluteNumbersCards
            color={statusColor[4]}
            name="há mais de 4 dias"
            value={indicators?.recentUpdateMore4Days}
          />
        </Grid>
          {/* <Grid container>
            <Grid size={{xs:12, md:6}}>
              <BarChart
                //dataset={data}
                loading={isLoading}
                  colors={['#009490']}
                  xAxis={[{ 
                    scaleType: 'band', 
                    dataKey: 'day',
                    data: labelToPlot,
                    //dataKey: 'year' 
                  
                  }]}
                  series={[{ data: dataToPlot }]}
                    
                  width={500}
                  height={300}
                />
            </Grid>
            <Grid size={{xs:12, md:6}}>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: 10, label: 'series A' },
                    { id: 1, value: 15, label: 'series B' },
                    { id: 2, value: 20, label: 'series C' },
                  ],
                },
              ]}
              width={400}
              height={200}
            />
            </Grid>
         
          </Grid> */}
         
        
        </ContainerLayout>
    </MainLayout>



  );
}
