import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, SxProps, Theme } from "@mui/material";

interface ConfirmButtonProps {
  readonly stepMode?: boolean;
  readonly onConfirmClick?: () => void;
  readonly type?: "button" | "submit" | "reset";
  readonly label?: string;
  readonly size?: "medium" | "small" | "large";
  readonly sx?: SxProps<Theme>;
  readonly colorButton?: "primary" | "inherit" | "secondary" | "success" | "error" | "info" | "warning";
  readonly loading?: boolean;
  readonly variant?: "contained" | "outlined";
  
}

export default function ConfirmButton({
  stepMode = false,
  onConfirmClick,
  type = "button",
  label,
  size = 'medium',
  sx,
  colorButton = 'primary',
  loading = false,
  variant= "contained"
}: ConfirmButtonProps) {
  return (
    <LoadingButton
      type={type}
      onClick={onConfirmClick}
      variant={variant}
      size={size}
      fullWidth
      color={colorButton}
      loading={loading}
      sx={{
        borderRadius: "8px",
        padding: 1,
        textTransform: "initial",
        lineHeight: "normal",
        margin: 1,
        ...sx
      }}
    >
      {label ? label : stepMode ? "Próximo" : "Confirmar"}
    </LoadingButton>
  );
}
