import { Card, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import dayjs, { Dayjs } from "dayjs";
import SchedulingCard from "../SchedulingCard";
import { State } from "@hookstate/core";
import { useNavigate } from "react-router-dom";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { Appointment } from "../../services/appointment";
import { useState } from "react";
import CancelSchedulingModal from "../../../Scheduling/components/CancelScheduling/CancelSchedulingModal";

interface ISchedulingTab {
  readonly date: State<Dayjs>;
  readonly locale: State<{ id: number; name: string } | null>;
  readonly value: State<number>;
  readonly data: State<any,any>;
  readonly hasClosedCancelModal?: ()=> void;

}

export default function SchedulingTab({
  date,
  locale,
  value,
  data,
  hasClosedCancelModal = () =>{}
}: ISchedulingTab) {
  const navigator = useNavigate()
  const [schedulingToCancel, setSchedulingToCancel] = useState<any>(null)

  const handleClick = (appointment: Appointment) => {
    // if (mock.status !== 'Cancelado' && mock.type_service === 'Instalação') {
    //   navigator("/scheduling/tracks-register", {state: {schedule: mock}});
    // }

    if (appointment.AppointmentStatus.status_key !== 'cancelled' && appointment.ServiceType.service_key === 'install') {
      ///agendamento/cadastro/rastreador
      navigator("/cadastro/rastreador", {state: {
        plate: appointment.plate,
        cpf: appointment.person_cpf,
        type_service: appointment.ServiceType.service_name,
        hour: dayjs(appointment.datetime).format('HH:mm:ss'),
        name: appointment.person_name,
        status: appointment.AppointmentStatus.status_name,
        citizenId:appointment?.userId,
        companyBranch: locale.get()?.id,
        callbackUrl: '/agendamento',
        appointment_id: appointment.id,
      }});
    }
  }

  const onCloseCancelModal = () => {
    setSchedulingToCancel(null)
    hasClosedCancelModal()
  }

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          padding: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">
          {date.get().format("DD/MM/YYYY")} -{" "}
          {locale.get() ? locale.get()?.name : "Todos os locais"}
        </Typography>
      </Card>
      <Grid size={12} sx={{ height: '50vh' }}>
        <Typography paddingY={2}>
          Clique no agendamento para registrar o serviço.
        </Typography>
        <Card variant="outlined" sx={{ width: "100%", border: 'hidden', maxHeight: '80%', overflow: 'auto', paddingRight: 2, paddingBottom: 2}}>
          <Grid container spacing={2}>
            {data.map((appointment: Appointment): any => (
              <Grid size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                <SchedulingCard
                  cpf={appointment.person_cpf}
                  hour={dayjs(appointment.datetime).format('HH:mm:ss')}
                  name={appointment.person_name}
                  plate={appointment.plate}
                  status={appointment.AppointmentStatus.status_name}
                  type_service={appointment.ServiceType.service_name}
                  phone={appointment?.person_phone}
                  handleClick={() => handleClick(appointment)}
                  onCancelScheduling={(sche:Appointment)=> setSchedulingToCancel({...sche, id: appointment?.id})}
                />
              </Grid>
            ))}
          </Grid>
        </Card>
      </Grid>
      {Boolean(schedulingToCancel) && (
        <CancelSchedulingModal 
          open={Boolean(schedulingToCancel)} 
          scheduling={schedulingToCancel} 
          hasClosed={onCloseCancelModal}
        />
      )}
      <Grid
        container
        size={12}
        sx={{ gap: 1 }}
        display={"flex"}
        justifyContent="flex-end"
        marginTop={2}
      >
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <CancelButton
            // label="Anterior"
            stepMode
            onCancelClick={() => value.set(value.get() - 1)}
          />
        </Grid>
      </Grid>
    </>
  );
}
