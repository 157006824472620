import React, { useEffect, useState } from "react";
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  Controller,
} from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface DataPickerFieldFormProps {
  readonly register: UseFormRegister<any>;
  readonly name: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly label?: string;
  required?: boolean;
  messageError?: string;
  value?: string;
  readonly setValue: UseFormSetValue<any>,
  disabled?: boolean
  id?: string


}


export default function DataPickerFieldForm({
  label="Data", 
  register, 
  control = {}, 
  name, 
  required = false, 
  messageError = "", 
  value ='', 
  setValue,
  disabled = false, 
  id = 'data_form',
}: DataPickerFieldFormProps) {

  useEffect(()=> {
    if (value !== '' && value !== undefined && value !== null) {
      setValue(name, getSanitizedDate(value))
    }
  }, [value])

  const getSanitizedDate = (date: string) => {
    if (date !== '' && date !== null && date !== undefined) {
      return dayjs(date);
    }
    return dayjs();
  };

  return (
    <Controller
    name={name}
    control={control}
    //defaultValue={getSanitizedDate(value)}  
    render={({ field: { onChange, value }, fieldState: { error } }) => (
   
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pt-br"}>
      <DatePicker
        label={label}
        value={getSanitizedDate(value)}
        //defaultValue={getSanitizedDate(value)}  
        slotProps={{
          textField: {
            required: required,
            size: "small",
            fullWidth: true,
            helperText: Boolean(error)?'Data Inválida': '',
            sx: {
              mt: 0,
              borderRadius: "50%",
              "& .MuiInputBase-root": {
                border: "0.2px",
                borderRadius: "10px",
                "& input": {
                  padding: 1,
                },
              },
            },
          },
        }}
        onChange={(value) => onChange(value)}
      />
    </LocalizationProvider>
  )} />
  );
}
