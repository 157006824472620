import React from "react";
import ListLinkSideBar from "../ListLinkSideBar";
import ShareLocationOutlinedIcon from "@mui/icons-material/ShareLocationOutlined";

export default function MenuMonitoramento({
  hiddenMenuLabel = false,
  onMenuClick = (menuItem: string) => {},
}) {
  const [openConfig, setOpenConfig] = React.useState<boolean>(false);

  const handleMenuClick = (menuItem: string) => {
    onMenuClick(menuItem);
  };


  return (
    <>
      <ListLinkSideBar
          //activeMenu={activeMenu === "rastreadores"}
          icon={<ShareLocationOutlinedIcon fontSize="medium" />}
          text={"Rastreadores"}
          showText={!hiddenMenuLabel}
          link="/admin/rastreadores"
          handleClick={() => handleMenuClick("/admin/rastreadores")}
      />
        
    </>
  );
}
