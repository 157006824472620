import React from 'react';
import TextField from '@mui/material/TextField';

export default function InputField(props: any) {
 return (
  <TextField
   {...props}
   size="small"
   fullWidth
   sx={{
    mt: 0,
    borderRadius: '50%',
    '& .MuiInputBase-root': {
     border: '0.2px',
     borderRadius: '10px',
     color: '#000',
     '& input': {
      padding: 1,
     },
    },
   }}
  />
 );
}
