import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { setPhoneMask } from "../../../../utils/PhoneUtil";

interface ISchedulingCard {
  readonly hour?: string;
  readonly type_service?: string;
  readonly name: string;
  readonly cpf: string;
  readonly plate: string;
  readonly status?: string;
  readonly handleClick: () => void;
  readonly onCancelScheduling?: (scheduling: any) => void;
  readonly phone: string;
}

export default function SchedulingCard({
  hour,
  type_service,
  name,
  cpf,
  plate,
  status,
  handleClick,
  onCancelScheduling = () => {},
  phone
}: ISchedulingCard) {


  const onClickCancel = (event:any) =>{
    event.stopPropagation()
    onCancelScheduling({
      hour,
      type_service,
      name,
      cpf,
      plate,
      status
    })
  }
  
  return (
    <Card
      sx={{ border: "2px solid #eaeaea", borderRadius: "8px" }}
      onClick={() => handleClick()}
    >
      <CardActionArea>
        <CardContent
          sx={{ pb: 1, position: "relative", backgroundColor: "#efefef" }}
        >
          <Grid container direction={"column"}>
            {hour && type_service && <Typography
              component="p"
              gutterBottom
              sx={{
                color: "text.secondary",
                fontSize: 16,
                display: "block",
                fontWeight: 'bold'
              }}
            >
              {hour} | {type_service.toUpperCase()}
            </Typography>}
            <Typography
              sx={{ color: "text.secondary", mb: 1, fontSize: "1rem" }}
            >
              <b>Nome do Cidadão: </b> {name}
            </Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  mb: 1,
                  fontSize: "1rem"
                }}
              >
                <b>Placa do Veículo: </b> {plate}
              </Typography>
  
              <Typography
                sx={{
                  color: "text.secondary",
                  mb: 1,
                  fontSize: "1rem"
                }}
              >
                <b>Status do Agendamento: </b> {status}
              </Typography>

              <Typography
                sx={{
                  color: "text.secondary",
                  mb: 1,
                  fontSize: "1rem"
                }}
              >
                <b>Telefone: </b> {setPhoneMask(phone)}
              </Typography>
          </Grid>
        </CardContent>
          </CardActionArea>
        <CardActions sx={{justifyContent:'center'}}>
          <Tooltip title="Cancelar Agendamento">
          <Button onClick={onClickCancel} sx={{textDecoration: 'underline'}}>Cancelar</Button>
            {/* <IconButton 
                aria-label="delete" 
           >
                <DeleteIcon />
              </IconButton> */}
          </Tooltip>
        </CardActions>
    </Card>
  );
}
