import React, { ReactElement } from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';


interface ListItemSideBarProps {
  readonly text: string,
  readonly icon?: ReactElement<any, any>,
  readonly showText?: boolean,
  readonly link: string,
  readonly activeMenu?: boolean,
  handleClick:() => void;
}

export default function ListSubLinkSideBar({text, showText = false, link, activeMenu, handleClick}: ListItemSideBarProps){
  return (
    <>
    {showText && (
    <Link to={link} style={{textDecoration: 'none', color: '#121212' }}>
          <ListItemButton 
            onClick={handleClick} 
            sx={{ 
              bgcolor:  activeMenu? 'primary.light': '',  
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
                "& .MuiListItemIcon-root": {
                  color: "white"
                }
              }}} >
           
                <ListItemText primary={text} sx={{textDecoration: 'none', pl: 1}}/>
        
          </ListItemButton>
    </Link>
    )}
    </>
  )
}