import React from "react";
import ListLinkSideBar from "../ListLinkSideBar";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";

import ListItemSideBar from "../ListItemSideBar";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Collapse from "@mui/material/Collapse";
import ListSubLinkSideBar from "../ListSubLinkSideBar";


export default function MenuRecepcao({
  hiddenMenuLabel = false,
  onMenuClick = (menuItem: string) => {},
}) {
  const [openConfig, setOpenConfig] = React.useState<boolean>(false);

  const handleMenuClick = (menuItem: string) => {
    onMenuClick(menuItem);
  };
  const openSubMenuConfig = () => {
    setOpenConfig(!openConfig);
  };

  return (
    <>
    
      <ListLinkSideBar
        icon={<PersonAddAlt1OutlinedIcon fontSize="medium" />}
        text={"Cadastro do Cidadão"}
        showText={!hiddenMenuLabel}
        link="/cadastro/cidadao"
        handleClick={() => handleMenuClick("/cadastro/cidadao")}
      />
      <ListLinkSideBar
        //activeMenu={activeMenu === "tracks-register"}
        icon={<AddLocationAltOutlinedIcon fontSize="medium" />}
        text={"Cadastro do Rastreador"}
        showText={!hiddenMenuLabel}
        link="/cadastro/rastreador"
        handleClick={() => handleMenuClick("/cadastro/rastreador")}
      />
      <ListItemSideBar
        icon={<SettingsOutlinedIcon fontSize="medium" />}
        text={"Configurações"}
        showText={!hiddenMenuLabel}
        handleClick={openSubMenuConfig}
      />
      <Collapse in={openConfig} timeout="auto">
        <ListSubLinkSideBar
          //icon={<SupportAgentOutlinedIcon fontSize="medium" />}
          text={"Cidadãos"}
          showText={!hiddenMenuLabel}
          link="/admin/cidadaos"
          handleClick={() => handleMenuClick("/admin/cidadaos")}
        />
      </Collapse>
    </>
  );
}
