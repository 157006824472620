export const setCPFMask = (cpf: string) => {
  if(cpf === '' || cpf === undefined || cpf === null)
      return ''
    
  cpf=cpf?.replace(/\D/g,"")
  cpf=cpf?.replace(/(\d{3})(\d)/,"$1.$2")
  cpf=cpf?.replace(/(\d{3})(\d)/,"$1.$2")
  cpf=cpf?.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
  return cpf
};

export const CPFUnMask = (cpf: string) => {
  if(cpf === '' || cpf === undefined || cpf === null)
      return ''
    
  cpf=cpf?.replaceAll(".","")
  cpf=cpf?.replace('-', '')
  return cpf
};
