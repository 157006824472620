import React, { useEffect, useState } from "react";
import {
  UseFormGetValues,
  UseFormRegister,
  Controller,
  UseFormSetValue,
} from "react-hook-form";
import InputField from "../../../Field";
import InputMask from "react-input-mask";
import { removeMask } from "../../../../utils/stringsManipulations";
import { State } from "@hookstate/core";

interface TextFieldProps {
  readonly register: UseFormRegister<any>;
  readonly name: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly label?: string;
  readonly required: boolean;
  readonly messageError: string;
  readonly value: any;
  readonly setValue: UseFormSetValue<any>;
  readonly disabled?: boolean;
  readonly id?: string;
  readonly mask?: any | undefined;
  readonly variable?: State<string>;
}

export default function TextField({
  label,
  register,
  control = {},
  name,
  required = false,
  messageError = "",
  value,
  setValue,
  disabled = false,
  id = "simple-input",
  mask,
  variable
}: TextFieldProps) {

  const handleChangeValue = (value: string) => {
    setValue(name, mask ? removeMask(value) : value)
    variable?.set(mask ? removeMask(value) : value)
  }

  useEffect(()=> {
    if (value !== undefined && value !== null) {
      setValue(name, value)
    }
  }, [value])

  return (
    <Controller
      {...register(name, { required: required ? messageError : false })}
      name={name}
      control={control}
      defaultValue={value}
      render={({ field, fieldState: { error } }) => {
        return mask ? (
          <InputMask
            {...field}
            mask={`${mask}`}
            maskChar="_"
            onChange={(event: { target: { value: string }; }) => handleChangeValue(event.target.value)}
            disabled={disabled}
            >
            <InputField
              helperText={error && required ? error.message : null}
              error={required ? !!error : false}
              label={label}
              name={name}
              id={id}
              required={required}
            />
          </InputMask>
        ) : (
          <InputField
            helperText={error && required ? error.message : null}
            error={required ? !!error : false}
            {...field}
            label={label}
            name={name}
            disabled={disabled}
            id={id}
            required={required}
          />
        );
      }}
    />
  );
}
