import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import notify from "../../../../utils/notify";
import { Typography } from "@mui/material";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import FormCrud from "../../../../components/Form/components/FormCrud";
import ContainerLayout from "../../../../core/Layout/ContainerLayout";
import MainLayout from "../../../../core/Layout/MainLayout";
import TitleLayout from "../../../../core/Layout/TitleLayout";
import { editUser, getUserById } from "../../services";

export default function EditOperator() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [messageError, setMessageError] = useState<any>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [fields, setFields] = useState<Array<any>>([
    {
      name: "full_name",
      type: "string",
      label: "Nome Completo",
      messageError: "O nome é obrigatório",
      required: true,
      value: "",
      id: "full_name_input",
    },
    {
      name: "cpf",
      type: "string",
      label: "CPF",
      disabled: true,
      messageError: "O CPF é obrigatório",
      value: "",
      id: "cpf_input",
      mask: "999.999.999-99",
    },
    {
      name: "phone",
      type: "string",
      label: "Telefone",
      value: "",
      id: "phone_input",
      mask: "(99) 99999-9999",
    },
    {
      name: "email",
      type: "string",
      label: "Email",
      required: true,
      value: "",
      id: "email_input",
    },
    {
      name: "birth_date",
      type: "date",
      label: "Data de Nascimento",
      model: "Nascimento",
      value: "",
      id: "birth_date_input",
    },
  ]);

  const submitForm = async (body: any) => {
    setError(false);
    setMessageError("");
    setProcessing(true);

    const { hasError, data, error } = await editUser(id, body);

    if (!hasError) {
      notify({ message: `Operador atualizado com sucesso`, type: "success" });
      setTimeout(() => navigate("/operador"), 1000);
    } else {
      setError(true);
      setMessageError(error);

      if (error?.invalid_params && error.invalid_params.length) {
        error.invalid_params?.map((item: { reason: string }) =>
          notify({
            message: `${item.reason}`,
            type: "error",
          })
        );
      } else if (error?.details.length) {
        notify({
          message: `${error.details}`,
          type: "error",
        });
      } else {
        notify({
          message: `Algo deu errado, tente novamento mais tarde.`,
          type: "error",
        });
      }
    }

    setProcessing(false);
  };

  const loadData = (id: string) => {
    setProcessing(true);
    getUserById(id)
      .then((data: any) => {
        const fildesCopy = [...fields];
        fildesCopy.forEach((f) => {
          if (f.name === "birth_date") {
            f.value = data["birthDate"];
          } else if (f.name === "full_name") {
            f.value = data["name"];
          } else {
            f.value = data[f.name];
          }
        });
        setFields(fildesCopy);
        setProcessing(false);
      })
      .catch(() => {
        notify({ message: `Operador não encontrado.`, type: "error" });
        setProcessing(false);
      });
  };

  useEffect(() => {
    if (id !== undefined && id !== null) {
      loadData(id);
    }
  }, [id]);

  return (
    <MainLayout>
      <TitleLayout>Editar Operador</TitleLayout>
      <ContainerLayout minHeight="50%">
        <FormCrud
          fields={fields}
          onSubmitForm={submitForm}
          messagesError={messageError}
          error={error}
          onCancelClick={() => navigate("/operador")}
          onConfirmClick={() => {}}
          column={4}
          loading={processing}
        />
      </ContainerLayout>
    </MainLayout>
  );
}
