import { authFetch } from "../../../services/fetch";

type GetAllCompanyBranches = {
    page?: number;
    limit?: number;
    filters?: {
        company_branch_name?: string;
    }
}


export type CompanyBranchProps = {
    "id": number;
    "company_branch_name": string;
    "street": string | null;
    "neighborhood": string | null;
    "state": string | null;
    "number": string | null;
    "complement": string | null;
    "city": string | null;
    "zip_code": string | null;
    "created_at": string;
    "updated_at": string;
    "deleted_at": string | null;
}

export async function getAllCompanyBranches({ page = 0, limit = 10, filters = {}}: GetAllCompanyBranches = {}): Promise<CompanyBranchProps[]> {
    try {
        const { data } = await authFetch({
            url: "/company-branch",
            method: "GET",
            params: {
                page: page,
                limit: limit,
                filters: filters
            }
        });
        return data?.data ?? [];
    } catch (error) {
    throw error;
    }
}