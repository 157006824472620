import {
  Card,
  CardActionArea,
  CardContent,
  Skeleton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect } from "react";
import { getUserById } from "../../../../Licensed/services";
import { ImmutableObject, useHookstate } from "@hookstate/core";
import { useNavigate } from "react-router-dom";
import { IDataTable } from "../../../../../components/CrudIndex";
import { ICitizen } from "../../../components/SearchCitizenTab";

interface IServiceCard {
  readonly plate: string;
  readonly user?: ImmutableObject<ICitizen>;
  readonly trackCode: string;
  readonly trackChip?: string | null;
  readonly brand?: string;
  readonly status?: string;
  readonly trackId?: number | null;
}

export default function ServiceCard({
  plate,
  status,
  trackChip,
  trackCode,
  brand,
  trackId,
  user
}: IServiceCard) {
  const navigate = useNavigate();
  const loading = useHookstate(false);

  const handleClick = () => {
    navigate("/servicos/instalacao", {
      state: {
        trackCode: trackCode,
        plate: plate,
        trackChip: trackChip,
        brand: brand,
        citizen: user,
        trackId: trackId
      },
    });
  };

  return (
    <Card
      sx={{ border: "2px solid #eaeaea", borderRadius: "8px" }}
      onClick={() => handleClick()}
    >
      <CardActionArea>
        <CardContent
          sx={{ pb: 1, position: "relative", backgroundColor: "#efefef" }}
        >
          <Grid container direction={"column"}>
            {loading.get() ? (
              <>
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem", width: '75%' }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem", width: '50%' }} />
              </>
            ) : (
              <>
              <Typography
                  sx={{ color: "text.secondary", mb: 1, fontSize: "1rem", textTransform: 'uppercase'}}
                  fontWeight={'bold'}
                >
                  Instalação
                </Typography>
                <Typography
                  sx={{ color: "text.secondary", mb: 1, fontSize: "1rem" }}
                >
                  <b>Nome do Cidadão: </b> {user?.name}
                </Typography>
                <Typography
                  sx={{
                    color: "text.secondary",
                    mb: 1,
                    fontSize: "1rem",
                  }}
                >
                  <b>Placa do Veículo: </b> {plate}
                </Typography>
                <Typography
                  sx={{
                    color: "text.secondary",
                    mb: 1,
                    fontSize: "1rem",
                  }}
                >
                  <b>Status do Agendamento: </b> {status}
                </Typography>
              </>
            )}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
