import { User } from "oidc-client-ts";

export const baseUrlKeyCloak = process.env.REACT_APP_KEYCLOAK_URL;

export const getUserTokenKeyCloak = async () => {
    const userStore = getUserInfoKeyCloak()
   
    if (userStore) {
      return userStore.access_token;
    }
    return null
  };


export function getUserInfoKeyCloak() {
    const oidcStorage = localStorage.getItem(
        `oidc.user:${baseUrlKeyCloak}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}:${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
    );
    if (!oidcStorage) {
    return null;
    }

    return User.fromStorageString(oidcStorage);
}

