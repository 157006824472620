import React, { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import InputField from "../../Field";
import { getVehiclePurpose } from "./services";

interface TextFieldProps {
  readonly required: boolean;
  readonly vehiclePurpose: number | null;
  readonly onChange: (value: number|undefined) => void
}

export interface PrimaryPlatformProps {
  readonly id: number;
  readonly name: string;
}

export default function FilterAutocompletePrimaryPlatform({
  required = false,
  vehiclePurpose,
  onChange
}: TextFieldProps) {
  const [options, setOptions] = useState<readonly PrimaryPlatformProps[]>([]);
  const [value, setValue] = useState<PrimaryPlatformProps| undefined | null>(null)

  useEffect(() => {
    getVehiclePurpose().then((response) => setOptions(response));
  }, []);

  useEffect(() => {
    if(vehiclePurpose){
      setValue(options.find((item) => item.id == vehiclePurpose))
    }
 
  }, [vehiclePurpose, options])

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option?.name ?? ""}
      isOptionEqualToValue={(option, value) => option === value}
      value={value}
      onChange={(_, data) => {
        onChange(data?.id);
      }}
      renderInput={(params) => (
        <InputField
          {...params}
          label={"Principal Plataforma"}
          required={required}
        />
      )}
    />
  );
}
