import Grid from "@mui/material/Grid2";
import { State, useHookstate } from "@hookstate/core";
import InputField from "../../../../components/Field";
import CancelButton from "../../../../components/Buttons/CancelButton";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import InputMask from "react-input-mask";
import { removePlateMask } from "../../../../utils/stringsManipulations";
import { Typography } from "@mui/material";
import { getTrackerByTrackerCode } from "../../services";
import FilterAutocompleteCompanyBranch from "../../../../components/Filters/FilterAutocompleteCompanyBranch";
import { number } from "zod";
import { useEffect, useState } from "react";
import TrackSplashFeedback from "./TrackSplashFeedback";

interface ITracksForm {
  readonly value: State<number>;
  readonly trackId: State<number | null>;
  readonly trackCode: State<string>;
  readonly plate: State<string>;
  readonly brand: State<string>;
  readonly trackChip: State<string>;
  readonly state?: any;
  readonly submitForm: () => void;
  readonly showCancelButton?: boolean;
  readonly companyBranch: State<number | null>;
  readonly authorization: State<string>;
}

export default function TrackFormTab({
  value,
  trackCode,
  plate,
  brand,
  state,
  trackChip,
  trackId,
  submitForm,
  showCancelButton = true,
  companyBranch,
  authorization,
}: ITracksForm) {
  const trackError = useHookstate(false);
  const trackChipError = useHookstate(false);
  const plateError = useHookstate(false);
  const locationError = useHookstate(false);
  const trackErrorMessage = useHookstate("O ID do rastreador é obrigatório");
  const loading = useHookstate(false);
  const [hasLocal, setLocal] = useState<boolean>(false)

  const isValidTrackCode = () => trackCode.get().length > 0;

  const handleValidTrackerData = (data: any) => {
    trackError.set(false);
    trackErrorMessage.set("O ID do rastreador é obrigatório");
    trackChip.set(data.chipCode || "");
    trackId.set(data.id || null);
  };

  const setTrackError = (message: string, isError: boolean = false) => {
    trackErrorMessage.set(message);
    trackError.set(isError);
    trackId.set(null);
  };

  const handleTrackerError = (err: any) => {
    if (err.statusCode === 409) {
      setTrackError("Rastreador já cadastrado a um cidadão.", true);
    } else {
      setTrackError("O ID do rastreador é obrigatório", false);
    }
  };

  const handleSearchChipCode = async () => {
    if (!isValidTrackCode()) {
      setTrackError("O ID do rastreador é obrigatório");
      return;
    }

    try {
      loading.set(true);
      const data = await getTrackerByTrackerCode(trackCode.get());

      if (!data.userId) {
        handleValidTrackerData(data);
      }
    } catch (err) {
      handleTrackerError(err);
    } finally {
      loading.set(false);
    }
  };

  const validateForm = (): boolean => {
    trackError.set(!trackCode.get().length);
    trackChipError.set(!trackChip.get().length);
    plateError.set(plate.get().length < 7);
    locationError.set(companyBranch.get() === null);

    return trackError.get() || trackChipError.get() || plateError.get() || locationError.get();
  };

  const handleConfirmClick = () => {
    const hasError = validateForm();

    if (!hasError) {
      submitForm();
    }
  };

  useEffect(()=>{
    if(state){
      setLocal(companyBranch.get() !== null && companyBranch.get() !== undefined? true: false)
    }

  }, [companyBranch.get()])

  const searchCodeTrack = (event: any) => {
    trackCode.set(event.target.value);
    const timeoutId = setTimeout(() => {
      if(event.target.value.length > 3){
        handleSearchChipCode()
      }
      trackCode.get().length && trackError.set(false);
      if (timeoutId) {
        clearTimeout(timeoutId);
       }
    }, 500);

   
  }

  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      {/* {!!state && (
        <Grid size={12}>
          {" "}
          <Typography>
            {" "}
            <b>Placa:</b> {plate.get()}{" "}
          </Typography>
        </Grid>
      )} */}

      {!state?.plate && (
        <Grid size={{ xs: 12, sm: 6, md: 3 }}>
          <InputMask
            mask={`aaa9*99`}
            value={plate.get()}
            onChange={(event: { target: { value: string } }) => {
              plate.set(
                removePlateMask(event.target.value).toLocaleUpperCase()
              );
              plate.get().length === 7 && plateError.set(false);
            }}
            disabled={!!state?.plate}
          >
            <InputField
              helperText={
                plateError.get() && "A placa do veículo é obrigatória"
              }
              error={plateError.get()}
              label="Placa do Veículo"
              required
            />
          </InputMask>
        </Grid>
      )}
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          label="Código do Rastreador"
          error={trackError.get()}
          helperText={trackError.get() && trackErrorMessage.get()}
          value={trackCode.get()}
          onChange={searchCodeTrack}
          //onBlur={() => handleSearchChipCode()}
          required
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          label="Chip do Rastreador"
          error={trackChipError.get()}
          helperText={
            trackChipError.get() && "O chip do rastreador é obrigatório"
          }
          value={trackChip.get()}
          onChange={(event: { target: { value: string } }) => {
            trackChip.set(event.target.value);
            trackChip.get().length && trackChipError.set(false);
          }}
          required
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          label="Marca do Rastreador"
          value={brand.get()}
          onChange={(event: { target: { value: string } }) => {
            brand.set(event.target.value);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          label="Autorização"
          value={authorization.get()}
          onChange={(event: { target: { value: string } }) => {
            authorization.set(event.target.value);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <FilterAutocompleteCompanyBranch
          onChange={(value) => {
            companyBranch.set(value ?? null)
            value !== undefined && value !== null && locationError.set(false)
          }}
          required
          companyBranchId={companyBranch.get()}
          error={locationError.get()}
          hasLocal={hasLocal}
        />
      </Grid>
      <Grid
        container
        size={12}
        sx={{ gap: 1 }}
        display={"flex"}
        justifyContent="flex-end"
        paddingTop={2}
      >
        {showCancelButton && (
          <Grid
            size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
            display={"flex"}
            sx={{ justifyContent: "flex-end" }}
          >
            <CancelButton
              onCancelClick={() => value.set(value.get() - 1)}
              stepMode
            />
          </Grid>
        )}
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton
            onConfirmClick={handleConfirmClick}
            //loading={loading.get()}
          />
        </Grid>
        <TrackSplashFeedback open={loading.get()} text="Verificando se o código já existe..."/>
      </Grid>
    </Grid>
  );
}
