import Grid from "@mui/material/Grid2";
import { SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormGeneratorFields from "../FormGeneratorFields";
import CancelButton from "../../../Buttons/CancelButton";
import ConfirmButton from "../../../Buttons/ConfirmButton";

export interface ICreateField {
  readonly name: string;
  readonly type: string;
  readonly messagesError?: string | [];
  readonly label?: string;
  value: any;
  required: boolean;
}

interface FormCrudProps {
  readonly fields: readonly ICreateField[];
  readonly messagesError: any;
  readonly onSubmitForm: (e: any) => void;
  readonly column?: number;
  readonly error: boolean;
  readonly onCancelClick: () => void;
  readonly stepMode?: boolean;
  readonly onConfirmClick: () => void;
  justifyContentButton?: string;
  readonly loading?: boolean;
  readonly sizeButtons?: {
    readonly xs: number;
    readonly sm: number;
    readonly md: number;
    readonly lg: number;
  };
}

export default function FormCrud({
  fields,
  onSubmitForm,
  column = 2,
  error = false,
  messagesError = {},
  onCancelClick,
  stepMode = false,
  onConfirmClick,
  justifyContentButton = "flex-end",
  loading = false,
  sizeButtons = { xs: 12, sm: 3, md: 2, lg: 1 },
}: FormCrudProps) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    trigger,
  } = useForm({ mode: "all" });

  const [errorss, setErrors] = useState<Array<any>>([]);

  const handleSubmitForm = (e: any) => {
    Object.keys(e).forEach((item) => {
      if (e[item] === undefined) {
        const field: any = fields.find((f) => item === f.name);
        if (field !== undefined) {
          switch (field.type) {
            case "string":
              e[item] = "";
              break;
            case "boolean":
              e[item] = false;
              break;
            default:
              break;
          }
        }
      }
    });

    onSubmitForm(e);
  };

  useEffect(() => {
    if (messagesError !== undefined && messagesError !== null) {
      const errorsMessage: SetStateAction<any[]> = [];
      if (messagesError?.title === "Validation Error") {
        if (
          messagesError?.invalid_params !== undefined &&
          messagesError?.invalid_params !== null
        ) {
          messagesError?.invalid_params.forEach((erro: any) =>
            errorsMessage.push(erro?.reason as never)
          );
        }
      } else {
        errorsMessage.push(messagesError?.title as never);
      }
      setErrors(errorsMessage);
    }
  }, [messagesError]);

  return (
    <Grid container component="form" onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid
        container
        size={12}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <FormGeneratorFields
          fields={fields}
          register={register}
          control={control}
          column={column}
          setValue={setValue}
          watch={watch}
        />
      </Grid>
      <Grid
        container
        size={12}
        sx={{ gap: 1, marginTop: 2 }}
        display={"flex"}
        justifyContent={justifyContentButton}
      >
        <Grid
          size={sizeButtons}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <CancelButton stepMode={stepMode} onCancelClick={onCancelClick} />
        </Grid>
        <Grid
          size={sizeButtons}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton type="submit" stepMode={stepMode} loading={loading} />
        </Grid>
      </Grid>
    </Grid>
  );
}
