import React, { ReactNode } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import PrimaryButton from '../../components/Buttons/PrimaryButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'

export interface ConfirmDialogProps {
  readonly open: any
  readonly title?: string
  readonly content?: string
  readonly handleClose?: () => void
  readonly handleAgree: () => void
  readonly closeLabel?: string
  readonly AgreeLabel?: string

  readonly moreInformation?: ReactNode
}

export default function ConfirmDialogFeedback({ open = false, title='', content='', handleClose, handleAgree, closeLabel = 'Cancelar', AgreeLabel = 'Ok', moreInformation }: ConfirmDialogProps) {
  return (
    <Dialog
      open={open}
    >
      {title && (
        <DialogTitle  >
          {title}
        </DialogTitle>
      )}

      {content  && (
        <DialogContent>
          <DialogContentText >
            {content}
          </DialogContentText>
        </DialogContent>
      )}

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        
      >
           <SecondaryButton
          onClick={handleClose}
        >
          {closeLabel}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => handleAgree()}
        >
          {AgreeLabel}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  )
}
