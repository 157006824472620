import React, {useEffect, useState} from 'react';
import {TextField} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
 UseFormGetValues,
 UseFormRegister,
 Controller,
 UseFormSetValue,
} from 'react-hook-form';
import InputMask from 'react-input-mask';
import InputField from '../../../Field';

interface CrudTextFieldProps {
 readonly register: UseFormRegister<any>;
 readonly name: string;
 readonly getValues?: UseFormGetValues<any>;
 readonly control?: any;
 readonly label?: string;
 required: boolean;
 messageError?: string;
 value?: string;
 readonly setValue: UseFormSetValue<any>;
 id?: string

}

export default function PhoneFieldForm({
 label,
 register,
 control = {},
 name,
 required = false,
 messageError = '',
 value,
 setValue,
 id='phone-form'

}: CrudTextFieldProps) {
    const [phoneValue, setPhoneValue] = useState('');

    useEffect(() => {
    if (value !== '' && value !== undefined && value !== null) {
    setValue(name, value);
    setPhoneValue(value);
    }
    }, [value]);
    
 return (
  <Controller
   defaultValue={phoneValue}
   {...register(name, {
    required: {
     value: required,
     message: messageError === '' ? 'Telefone é obrigatório' : messageError,
    },
    pattern: {
     value:/(^[0-9]{2})?(\s|-)?(9?[0-9]{4})-?([0-9]{4}$)/i ,
     message:  'Telefone inválido',
    },
   })}
   name={name}
   control={control}
   render={({field, fieldState: {error}}) => {
    return (
        <InputMask
            mask={'(99) 99999-9999'}
            {...field}
            //maskChar="_"
           
        >
       <InputField
          helperText={error && required? error.message : null}
          error={required?!!error: false}
          label={label}
          name={name}
          id={id}
          required={required}
        />
     </InputMask>
    );
   }}
  />
 );
}
