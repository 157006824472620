
import Grid from "@mui/material/Grid2";
import { Typography } from "@mui/material";
import ReactInputMask from "react-input-mask";
import InputField from "../../../../components/Field";
import { State } from "@hookstate/core";

export interface EditCitizenModalProps {
  readonly plate: State<string>;
  readonly getValuePLaca?:Function;
}

export default function EditPlate({
  plate,
  getValuePLaca = () =>{}
}: EditCitizenModalProps) {


  const onChange = (placaString: string)=> {
    getValuePLaca()
  }

  return (
    <>
      <Typography sx={{ width: "100%" }} variant="h6" fontWeight={"bold"}>
        Placa
      </Typography>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
         <ReactInputMask    
                mask="aaa-9*99"
                maskChar="_"
                value={plate.get()}
                required
                onChange={(event: { target: { value: string } }) =>
                  plate.set(event.target.value)

                }
              >
                <InputField label="Placa" required />
              </ReactInputMask>
        </Grid>
      </>
         );
}
