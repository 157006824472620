import React, { useEffect, useState } from 'react';
import {  MenuItem, Select, InputLabel, FormControl, TextField, Autocomplete, Chip, InputBase } from '@mui/material';
import { Controller, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import SelectBase from '../../../../components/Field/SelectBase';
import InputField from '../../../../components/Field';

type CrudSelectFieldProps = {
  readonly register: UseFormRegister<any>;
  readonly name: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly label?: string;
  required?: boolean;
  messageError?: string;
  value?: string;
  readonly setValue: UseFormSetValue<any>;
  disabled?: boolean;
  data?: Array<any>;
  id?: string;
  onClearData?: ()=> void
} 
export function ChipApp({color= '', label='', onClick = ()=>{}}) {
  return(
      <Chip 
          size="small" 
          label={label}  
          component="button"
          sx={{ bgcolor: color}}   
          clickable  
          onClick={onClick}
          />
  )
}  
export default function LastUpdateFieldDataGrid({ 
  label="",
  register,
  control = {},
  name="select",
  required = false,
  value = '-1',
  onClearData = () => {}

}: CrudSelectFieldProps) {

  const optionChip = [
    {label: 'Menos 15min', value: '', color:"#baffd0"},
    {label: 'Menos 6h', value: '',  color:"#f7fb7b"},
    {label: 'Menos 4 dias', value: '',  color:"#f3c26e"},
    {label: 'Mais 4 dias', value: '', color:"#ffa89b"},
  ]
  return (
      <Controller
        { ...register(name, {required: required ? '' : false}) }
        name={name}
        control={control}
        render={({ field, fieldState:{error} }) => (
          <Autocomplete
          {...field}
          multiple
          id="tags-filled"
          options={optionChip.map((option) => option.label)}
          //defaultValue={[top100Films[13].title]}
          freeSolo
          renderTags={(value: readonly string[]) =>
            value.map((option: string, index: number) => {
             
              return (
                <ChipApp label={option} color={optionChip[index]?.color}/>
              );
            })
          }
          onChange={(_, data, reason) => {
            console.log(data, 'onchange')
            if (reason === 'clear') {
           
             field.onChange(null);
            } else {
             field.onChange(data);
            }
           }}
          renderInput={(params) => (
            <InputField
              label="Atualizado há"
              {...params}

            />
          )}
        />
        )}
      />
  );  
}
