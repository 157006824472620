import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';
import IconButton  from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';


export default function GridActionCrud ({hasSeeMoreClicked = ()=>{}, hasEditClicked=()=>{}, onResetPasswordClicked = ()=>{}}) {

  return (
    <Grid container justifyContent={"center"} alignItems={"center"} sx={{height:'100%'}}>
      
      {/* <Tooltip title="Mais Informação">
        <IconButton
            aria-label="Mais infomrações"
            onClick={hasSeeMoreClicked}
            
          >
          <RemoveRedEyeIcon />
        </IconButton>  
      </Tooltip> */}
      {/* {(isIntegrador || isAdmin) && ( */}
      <Tooltip title="Redefinir Senha">
        <IconButton
            aria-label="Redefinir Senha Cidadão"
            onClick={onResetPasswordClicked}
            
            >
          <RestartAltIcon />
        </IconButton>  
      </Tooltip>
        <Tooltip title="Editar Cidadão">
          <IconButton
              aria-label="Editar Cidadão"
              onClick={hasEditClicked}
              
              >
            <EditIcon />
          </IconButton>  
        </Tooltip>
          {/* )} */}
    </Grid>
  )
} 

