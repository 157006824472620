import Grid  from "@mui/material/Grid2";
import Paper from '@mui/material/Paper';


import React, { ReactNode } from "react";
import SplashFeedback from "../Feedbacks/SplashFeedback";

type LayoutProps = {
    readonly children: ReactNode;
    loading?: boolean;
    minHeight?: string;
    maxHeight?: string;
}

export default function ContainerLayout({ children, loading = false, minHeight = '85vh', maxHeight = 'auto'}: LayoutProps) {
    return (
        <Grid container>
            {loading ? (
             <SplashFeedback open={loading}/>
            ):(

            <Paper elevation={3} sx={{width: '100%', p:2, minHeight: minHeight, borderRadius: 6, maxHeight: maxHeight}}>
                {children}
            </Paper>
            )}
        </Grid>    
    )
}
