export const setPlateMask = (plate: string) => {
  if(plate === '' || plate === undefined || plate === null)
      return ''

  const parte1 = plate.slice(0,3)
  const parte2 = plate.slice(3,7)
  return `${parte1}-${parte2}`
};

export const removeMaskPlate = (value: string | null) => {
  return value?.replace('-', "") ?? ''
};