import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormCrud from "../../../../components/Form/components/FormCrud";
import MainLayout from "../../../../core/Layout/MainLayout";
import TitleLayout from "../../../../core/Layout/TitleLayout";
import ContainerLayout from "../../../../core/Layout/ContainerLayout";
import { useHookstate } from "@hookstate/core";
import notify from "../../../../utils/notify";
import { createAttendant } from "../../services";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { Typography } from "@mui/material";

export default function CreateAttendant() {
  const navigate = useNavigate();
  const [messageError, setMessageError] = useState<any>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [fields, setFields] = useState<Array<any>>([
    {
      name: "full_name",
      type: "string",
      label: "Nome Completo",
      messageError: "O nome é obrigatório",
      required: true,
      value: "",
      id: "full_name_input",
    },
    {
      name: "cpf",
      type: "string",
      label: "CPF",
      required: true,
      messageError: "O CPF é obrigatório",
      value: "",
      id: "cpf_input",
      mask: "999.999.999-99",
    },
    {
      name: "phone",
      type: "string",
      label: "Telefone",
      required: true,
      messageError: "O telefone é obrigatório",
      value: "",
      id: "phone_input",
      mask: "(99) 99999-9999",
    },
    {
      name: "email",
      type: "string",
      label: "Email",
      required: true,
      value: "",
      id: "email_input",
    },
    {
      name: "birth_date",
      type: "date",
      label: "Data de Nascimento",
      model: "Nascimento",
      messageError: "A Data de nascimento é obrigatório",
      required: true,
      value: "",
      id: "birth_date_input",
    }
  ]);
  const openConfirmDialog = useHookstate(false);

  const handleAgree = () => {
    navigate("/teleatendente");
    openConfirmDialog.set(false);
  };

  const submitForm = async (body: any) => {
    setError(false);
    setMessageError("");
    setProcessing(true);

    const { hasError, data, error } = await createAttendant(body);

    if (!hasError) {
      openConfirmDialog.set(true);
      notify({ message: `Teleatendente cadastrado com sucesso`, type: "success" });
    } else {
      setError(true);
      setMessageError(error);

      if (error?.invalid_params && error.invalid_params.length) {
        error.invalid_params?.map((item: { reason: string }) =>
          notify({
            message: `${item.reason}`,
            type: "error",
          })
        );
      } else if (error?.details.length) {
        notify({
          message: `${error.details}`,
          type: "error",
        });
      } else {
        notify({
          message: `Algo deu errado, tente novamento mais tarde.`,
          type: "error",
        });
      }
    }

    setProcessing(false);
  };

  return (
    <MainLayout>
      <TitleLayout>Cadastro do Teleatendente</TitleLayout>
      <ContainerLayout minHeight="50%">
        <FormCrud
          fields={fields}
          onSubmitForm={submitForm}
          messagesError={messageError}
          error={error}
          onCancelClick={() => navigate("/teleatendente")}
          onConfirmClick={() => {}}
          column={4}
          loading={processing}
        />
      </ContainerLayout>
      <ConfirmDialog
        handleAgree={handleAgree}
        open={openConfirmDialog}
        title="Cadastro realizado com sucesso"
        moreInformation={
          <Typography>
            Para fazer login, o teleatendente deve utilizar o <b>CPF</b> e a{" "}
            <b>senha temporária enviada por email</b>.
          </Typography>
        }
      />
    </MainLayout>
  );
}
