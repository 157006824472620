import React, { useState } from "react";
import {
  DataGrid,
  GridSortModel,
  GridColDef,
  GridRowModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { ImmutableArray, useHookstate } from "@hookstate/core";
import SearchField from "../Filters/SearchField";
import Grid from "@mui/material/Grid2";
import { IEntry } from "../../features/Licensed/interfaces";

export interface IDataTable {
  readonly id: any;
  readonly first_name: string;
  readonly name: string;
  readonly last_name: string;
  readonly email: string;
  readonly username: string;
  readonly roles: string;
  readonly cpf: string;
  readonly birthDate: string;
  readonly enabled: string;
  readonly groups: {
    readonly name: string
  }[];
}

interface ICrudIndex {
  readonly columns: GridColDef[];
  readonly row: ImmutableArray<IDataTable>;
  readonly rowCount: number;
  readonly loading: boolean;
}

export default function CrudIndex({ columns, row, rowCount, loading }: ICrudIndex) {
  const pageSizeOptions = [5, 15, 50, 100];

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(pageSizeOptions[1]);

  return (
    <>
      <Card sx={{ p: 3, width: "100%", borderRadius: "24px", mt: 5 }}>
        <Grid container>
          <Grid size={12}>
            <DataGrid
              localeText={{
                MuiTablePagination: {
                  labelDisplayedRows: ({ from, to, count }) =>
                    `mostrando ${row.length} registros de ${count}`,
                },
              }}
              sx={{
                border: 0,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                },
              }}
              autoHeight={true}
              pageSizeOptions={pageSizeOptions}
              loading={loading}
              rows={row}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: limit } },
              }}
              onPaginationModelChange={({ page, pageSize }) => {
                setPage(page);
                setLimit(pageSize);
              }}
              pagination
              disableRowSelectionOnClick
              // paginationMode={"server"}
              // filterMode={"server"}
              rowCount={rowCount}
              slotProps={{
                pagination: {
                  labelRowsPerPage: "Teleatendentes por página",
                },
              }}
              disableColumnSelector={true}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
