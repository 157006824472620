import React from "react";
import ListLinkSideBar from "../ListLinkSideBar";
import ShareLocationOutlinedIcon from "@mui/icons-material/ShareLocationOutlined";


export default function MenuCidadão({hiddenMenuLabel=false,   onMenuClick = (menuItem:string)=>{}}) {
   
  const handleMenuClick = (menuItem: string) => {
    onMenuClick(menuItem)
  };


  return( 
  <>
    <ListLinkSideBar
    //activeMenu={activeMenu === "tracks"}
    icon={<ShareLocationOutlinedIcon fontSize="medium" />}
    text={"Rastreadores"}
    showText={!hiddenMenuLabel}
    link="/rastreadores"
    handleClick={() => handleMenuClick("tracks")}
  />
  </>
)
}
