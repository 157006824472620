import Grid from '@mui/material/Grid2';
import MainLayout from '../../../core/Layout/MainLayout';
import ContainerLayout from '../../../core/Layout/ContainerLayout';
import TitleLayout from '../../../core/Layout/TitleLayout';
import React, { useCallback, useState } from 'react';
import MapApp from '../../../components/Map/MapApp';
import {LatLng} from 'leaflet';
import InputField from '../../../components/Field';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import SearchIcon from "@mui/icons-material/Search";
import notify from '../../../utils/notify';
import { getLastTrackPosition } from '../services';
import { CircularProgress, IconButton, InputAdornment, Typography } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';

const latLongs = {
  PB: new LatLng(-7.150506, -34.856918),
  CE: new LatLng(-3.781354, -38.533661),
  BA: new LatLng(-12.962435, -38.459241),
 };
type Cord = {
  lat: number;
  long: number;
}
export default function SearchTracksMapPage() {
  const [coord, setCord] = useState<Cord>({lat: 0, long:0})
  const [isLoading, setIsLoading]=useState<boolean>(false);
  const [trackCode, setTrackCode] =useState<string>('');
  const [hasSearch, setHasSearch]= useState<boolean>(false);


  const localizateTrackOnMap = async () => {
    if(trackCode.length > 0 ){
      setIsLoading(true)
      setHasSearch(false)
      setCord({lat: 0, long: 0})
        await getLastTrackPosition(trackCode)
        .then((data) => {
          if (data && data.location?.length) {
            setCord({lat: data.location[0], long:data.location[1]})
            
          }
        
        })
        .catch((err) => {
          notify({
            message: `Algo deu errado, tente novamento mais tarde.`,
            type: "error",
          });
          console.error(err);
    
        })
        .finally(() =>  { 
          setIsLoading(false);    
          setHasSearch(true)
          //return true
        });
      }else{
        notify({
          message: `Atenção! O código do rastreador não pode está vazio.`,
          type: "error",
        });
      }  
   }


   const showMap = useCallback( ()=> {

     if(coord.lat !== 0 && coord.long !== 0){
      return <MapApp lat={coord.lat} lng={coord.long}   zoom={15}
      height={"60vh"}/>
    }else if(coord.lat === 0 && coord.long === 0){
      if(hasSearch){
        return (
          <Typography
          //align="center"
          sx={{ color: "#a63939", fontSize: "1rem" }}
        >
          Não foi identificado o recebimento dos dados de localização deste
          rastreador.
        </Typography>
        )
      }
     
    }
   }, [coord])


   const cleanInput = () => {
    setTrackCode('')
   }

          

  return (
    <MainLayout>
      <TitleLayout>
        Localizar Rastreador
      </TitleLayout>
      {/* <Container maxWidth={'md'}> */}
          <ContainerLayout  minHeight='auto'>
            <Grid container sx={{pb:2}} alignContent={'center'}>
              <Grid >
                <InputField 
                  required={true} 
                  label="Código do Rastreador" 
                  value={trackCode}
                   onChange={(e:any)=>{
                      setTrackCode(e.target.value)
                    }}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end"> 
                         <IconButton
                          aria-label="Ver Código do Rastreador"
                          onClick={cleanInput}
                          sx={{p:0.5, mr: -1.5}}
                          >
  
                        <CloseIcon />       
                          </IconButton>
                        </InputAdornment>,
                      },
                    }}
                />
              </Grid>
              <Grid sx={{pt:0.2, pl:1}}>
                <PrimaryButton disabled={isLoading} onClick={ () => localizateTrackOnMap()}>
                  <SearchIcon />
                  Localizar
                </PrimaryButton>
              </Grid>
            </Grid>
          
            <Grid container justifyContent={"center"} alignItems={"center"}>
              {showMap()}
              { isLoading &&(
                <CircularProgress size={120}/>
              ) }
            </Grid>
          </ContainerLayout>
        {/* </Container>
         */}
      
    </MainLayout>
 
 
 
  );
}
