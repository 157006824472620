import React, { ReactElement } from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


interface ListItemSideBarProps {
  readonly text: string,
  readonly icon?: ReactElement<any, any>,
  readonly showText?: boolean,
  readonly activeMenu?: boolean,
  handleClick: (open: boolean) => void
}

export default function ListItemSideBar({text, icon, showText = false, activeMenu, handleClick}:ListItemSideBarProps){
  const [open, setOpen] = React.useState(false);

  const openSubMenu = () => { 
    handleClick(!open)
    setOpen(!open);
  }

  return (
          <ListItemButton onClick={openSubMenu} sx={{ borderLeft:  activeMenu? '6px solid': 'none', borderLeftColor:  'primary.main'}} >
            <ListItemIcon >
              {icon}
            </ListItemIcon>
            {showText && (
              <>
                <ListItemText primary={text} sx={{textDecoration: 'none'}}/>
                {open ? <ExpandLess /> : <ExpandMore />}
              </>  
            )}
          </ListItemButton>
    )
}