import React, { useEffect, useState } from "react";
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  Controller,
} from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface SelectDateProps {
  readonly name: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly label?: string;
  required: boolean;
  value: Dayjs | null;
  onChange: (value: any) => void;
}

export default function SelectDate({
  label,
  name,
  required = false,
  value,
  onChange,
}: SelectDateProps) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pt-br"}>
      <DatePicker
        label={label}
        value={value as Dayjs}
        slotProps={{
          textField: {
            required: required,
            size: "small",
            fullWidth: true,
            sx: {
              mt: 0,
              borderRadius: "50%",
              "& .MuiInputBase-root": {
                border: "0.2px",
                borderRadius: "10px",
                "& input": {
                  padding: 1,
                },
              },
            },
          },
        }}
        onChange={(value) => onChange(value)}
      />
    </LocalizationProvider>
  );
}
