import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { State } from "@hookstate/core";
import FilterAutocompleteCompanyBranch from "../../../../components/Filters/FilterAutocompleteCompanyBranch";
import FilterAutocompletePrimaryPlatform from "../../../../components/Filters/FilterAutoCompletePrimaryPlatform";

interface ApplicationDataRegisterProps {
    readonly principalPlatform: State<number|null>
    readonly companyBranch: State<number|null>
}

export default function ApplicationDataRegister({principalPlatform, companyBranch}:ApplicationDataRegisterProps) {
  return (
    <>
      <Typography sx={{ width: "100%" }} variant="h6" fontWeight={"bold"}>
        Dados Moto Segura
      </Typography>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <FilterAutocompletePrimaryPlatform onChange={(value) => principalPlatform.set(value ?? null)} required vehiclePurpose={principalPlatform.get()} />
      </Grid>
      {/* <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <FilterAutocompleteCompanyBranch onChange={(value) => companyBranch.set(value ?? null)} required value={companyBranch.get()} />
      </Grid> */}
    </>
  );
}