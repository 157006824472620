import React, { useState } from 'react';

import MainLayout from '../../../core/Layout/MainLayout';
import ContainerLayout from '../../../core/Layout/ContainerLayout';
import TitleLayout from '../../../core/Layout/TitleLayout';
import CreateAccreditedForm from '../components/Forms/CreateAccreditedForm';
import { AccreditedStepContextProvider } from '../contexts/AccreditedStepContext';
import { AccreditedFormContextProvider } from '../contexts/AccreditedFormContext';


function RegisterAccreditedPage() {
  return (
    <MainLayout>
       <TitleLayout>
          Cadastro Empresa Credenciada
        </TitleLayout>
        <ContainerLayout minHeight="auto">
          <AccreditedStepContextProvider>
            <AccreditedFormContextProvider>
              <CreateAccreditedForm />    
            </AccreditedFormContextProvider>
          </AccreditedStepContextProvider>
        </ContainerLayout>
    </MainLayout>



  );
}

export default RegisterAccreditedPage;
