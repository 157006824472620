import React, { useEffect, useState } from "react";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

export function ChipApp({color= '', label='', onClick = ()=>{}}) {
    return(
        <Chip 
            size="small" 
            label={label}  
            component="button"
            sx={{ bgcolor: color}}   
            clickable  
            onClick={onClick}
            />
    )
}  

export default function GridLabel({onChipIsClicked = (array: Array<any>) => {}}) {
    const [selectedChip, setSelectedChip] = useState<Array<any>>([])


    const getSelectedChip = (obj: any) =>{
        // const findIndex = selectedChip.find(index)
        // if(findIndex === -1){
        //     selectedChip.push(index)
        //     setSelectedChip( selectedChip)
        // }else{
        //     const newArrray = selectedChip.splice(index, 0)
        //     setSelectedChip(newArrray)
        //   setSelectedChip(prevState => ([...[prevState], selectedChip]))
        // }
        // console.log(index,'index', selectedChip)

      
    }

    useEffect( ()=>{
        console.log(selectedChip, 'selectedChip')
        onChipIsClicked(selectedChip)
    }, [selectedChip])

    return (
        <Grid container >
        <Typography component='span' sx={{pr:1,pt:'2px', fontSize:'0.899rem'}}>
          <strong>Atualizado há:</strong>
        </Typography>
        <Stack direction="row" spacing={1} sx={{pb:2}} alignItems={"center"}>
            <ChipApp 
                label="menos 15min"  
                color="#baffd0"
                onClick={()=>{getSelectedChip({id:'m15min', label:'menos 15min'})}}
                />
            <ChipApp 
              
                label="menos 6h"
                color= '#f7fb7b'
                //onClick={()=>{getSelectedChip(1)}}
                />
            <ChipApp
                label="menos 4 dias" 
               color= '#f3c26e'
               //onClick={()=>{getSelectedChip(2)}}
               
                />
            <ChipApp 
            label="mais 4 dias"  
            color= '#ffa89b'
            onClick={()=>{getSelectedChip(2)}}
           
            />
            {/* <Chip size="small" label="Atualizado a 3 dias" color="error" variant="outlined"/> */}
            
      </Stack>        
      </Grid>
    )
}
