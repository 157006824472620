import { Tab, Tabs, Typography } from "@mui/material";
import MainLayout from "../../../../core/Layout/MainLayout";
import TabPanel from "../../../../components/TabPanel";
import SearchCitizenTab, { ICitizen } from "../../components/SearchCitizenTab";
import { useHookstate } from "@hookstate/core";
import TrackFormTab from "../../components/TrackFormTab";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { createTracker, updateTracker } from "../../services";
import notify from "../../../../utils/notify";
import { ITrack } from "../../interface";
import ContainerLayout from "../../../../core/Layout/ContainerLayout";
import TitleLayout from "../../../../core/Layout/TitleLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getUserById } from "../../../Licensed/services";
import GridDataDisplay from "../../../../components/DataDisplay/GridDataDisplay";
import { sanitizerName } from "../../../../utils/StringsManipulationsUtil";
import { setPlateMask } from "../../../../utils/PlateUtil";

function TracksRegisterPage() {
  const { state }: { state: { citizenId: string, plate: string, companyBranch: number, callbackUrl: string, appointment_id: number } } = useLocation();
  const navigate = useNavigate();


  const trackCode = useHookstate("");
  const citizenId = useHookstate<string | null>(state ? state.citizenId : null);
  const openConfirmDialog = useHookstate(false);
  const brand = useHookstate("");
  const value = useHookstate(0);
  const plate = useHookstate("");
  
  const trackChip = useHookstate("");
  const idTracker = useHookstate<number | null>(null);
  const citizenObj = useHookstate<ICitizen | null>(null);
  const companyBranch = useHookstate<number | null>(null);
  const authorization = useHookstate<string>("");
  const appointmentId = useHookstate(state?.appointment_id);


  if(state && state?.plate){
    if(state?.plate !== undefined && state?.plate !== null){
      plate.set(state?.plate)
    }
  }

  if(state && state?.companyBranch){
    companyBranch.set(state?.companyBranch)
  }

  const callbackUrl: string = state?.callbackUrl? state?.callbackUrl : ''

  

  const getTrackerData = (): ITrack => {
    const brandObj =
      brand.get() && brand.get().length > 0 ? { brand: brand.get() } : {};
    const authObj =
      authorization.get() && authorization.get().length > 0
        ? { authorization: authorization.get() }
        : {};
    const idObj = idTracker.get() ? { id: idTracker.get() } : {};

    return {
      plate: plate.get(),
      trackCode: trackCode.get(),
      userId: citizenId.get(),
      trackChip: trackChip.get(),
      companyBranchId: companyBranch.get(),
      ...brandObj,
      ...idObj,
      ...authObj,
     appointment_id: appointmentId.get()
    };
  };

  const saveTracker = async (trackerData: ITrack) => {
    return await createTracker(trackerData);
  };

  const handleTrackerErrors = (error: any) => {
    if (error?.invalid_params && error.invalid_params.length) {
      error.invalid_params?.map((item: { reason: string }) =>
        notify({
          message: `${item.reason}`,
          type: "error",
        })
      );
    } else if (error?.details.length) {
      notify({
        message: `${error.details}`,
        type: "error",
      });
    } else {
      notify({
        message: `Algo deu errado, tente novamente mais tarde.`,
        type: "error",
      });
    }
  };

  const handleSuccess = () => {
    openConfirmDialog.set(true);
    notify({
      message: `Rastreador cadastrado com sucesso`,
      type: "success",
    });
  };

  const submitForm = async () => {
    try {
      const trackerData = getTrackerData();

      const { hasError, data, error } = await saveTracker(trackerData);

      if (hasError) {
        handleTrackerErrors(error);
      } else {
        handleSuccess();
      }
    } catch (err) {
      notify({
        message: `Algo deu errado, tente novamente mais tarde.`,
        type: "error",
      });
    }
  };

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }



  useEffect(() => {
    if (state && citizenId.get()) {
      getUserById(citizenId.get())
        .then((data) => citizenObj.set(data))
        .catch((err) =>
          notify({
            message:
              "Algo deu errado, tente novamente. Cidadão não encontrado.",
            type: "error",
          })
        );
    }
  }, [state]);

  return (
    <MainLayout>
      <TitleLayout>Cadastro do Rastreador</TitleLayout>
      {state && (
        // <SubtitleLayout>Cidadão: {citizenObj.get()?.name}</SubtitleLayout>
        <>
          <GridDataDisplay titulo={'Cidadã(o)'} info={sanitizerName(citizenObj.get()?.name as string)}/>
        </>
      )}
       {state?.plate && (
       
        <>
          <GridDataDisplay titulo='Placa' info={setPlateMask(plate.get())}/>
        </>
      )}
     
      <ContainerLayout minHeight="50%">
        <Tabs value={value.get()} aria-label="basic tabs example">
          {!state && <Tab label="Buscar Cidadão" {...tabProps(0)} />}
          <Tab label="Cadastrar Rastreador" {...tabProps(state ? 0 : 1)} />
        </Tabs>

        {!state && (
          <TabPanel value={value.get()} index={0}>
            <SearchCitizenTab
              citizenId={citizenId}
              value={value}
              citizenObj={citizenObj}
            />
          </TabPanel>
        )}
        <TabPanel value={value.get()} index={state ? 0 : 1}>
          <TrackFormTab
            value={value}
            trackCode={trackCode}
            plate={plate}
            brand={brand}
            trackChip={trackChip}
            trackId={idTracker}
            submitForm={() => submitForm()}
            showCancelButton={!state}
            companyBranch={companyBranch}
            authorization={authorization}
            state={state}
          />
        </TabPanel>
      </ContainerLayout>

      <ConfirmDialog
        title="Rastreador cadastrado com sucesso!"
        moreInformation={
          <Typography>
            No momento agendado, direcione o cidadão até o local de instalação
            do equipamento no veículo.
          </Typography>
        }
        open={openConfirmDialog}
        handleAgree={() => {
          openConfirmDialog.set(false);
          if(state !== undefined && state !== null){
            navigate(callbackUrl);
          }else{
            navigate("/cadastro/cidadao");
          }
        }}
      />
    </MainLayout>
  );
}

export default TracksRegisterPage;
