import React, { useEffect, useState } from 'react';
import {  MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { Controller, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import SelectBase from '../../../../components/Field/SelectBase';

type CrudSelectFieldProps = {
  readonly register: UseFormRegister<any>;
  readonly name: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly label?: string;
  required?: boolean;
  messageError?: string;
  value?: string;
  readonly setValue: UseFormSetValue<any>;
  disabled?: boolean;
  data?: Array<any>;
  id?: string
} 

export default function SelectFieldDataGrid({ 
  label="",
  register,
  control = {},
  name="select",
  required = false,
  messageError = '',
  value = '-1',
  setValue,
  disabled = false,
  data = [],
  id="simple-input"

}: CrudSelectFieldProps) {

  const [shrink, setShrink] = useState<boolean>(false);
  const leaveShrink = ( ) => {
    if(value.length > 0)
      setShrink(true)
  }
  return (
      <Controller
        { ...register(name, {required: required ? '' : false}) }
        name={name}
        control={control}
        render={({ field, fieldState:{error} }) => (
        <FormControl fullWidth error={error!== null && error !== undefined? Boolean(error) : false}  size="small">
          <InputLabel id="demo-simple-select-helper-label"  focused={shrink} shrink={shrink}>{label}</InputLabel>
          <Select
         
            notched={shrink}
            label={label}
            {...field}
            id={id}
            size="small"
            sx={{
              borderRadius: '10px',
              color: '#000',
             // height
              
            }}
          
            value={field.value ? field.value : null}
            onBlur={leaveShrink}
            onClick={ () => setShrink(true)}
            disabled={disabled}
            //input={<SelectBase />}
            
          >
          {data?.map((option:any) => {
              return (
                <MenuItem key={option} value={option?.value} >
                  {option?.label}
                </MenuItem>
              )
            })}           
          </Select>
          <FormHelperText id="error-select-form">{error && required ? error.message : null}</FormHelperText>
        </FormControl>
        )}
      />
  );  
}
