import { authFetch } from "../../../services/fetch";

export async function cancelScheduling(id:number, coment: string, cancellation_reason_id: string) {
  try {
    const response = await authFetch({
      url: `/appointment/${id}/cancel`,
      data: {
        comments: coment,
        cancellation_reason_id
      },
    });
    return { hasError: false, data: response.data };
  } catch (error) {
    console.error("~ CancelScheduling: ", error);
    return { hasError: true, error: error as never };
  }
}


export async function getStatusCancelScheduling() {
  try {
    const response = await authFetch({
      url: `/cancellation-reason`,
      method: 'GET'
    });
    return { hasError: false, data: response.data };
  } catch (error) {
    console.error("~ getStatusCancelScheduling: ", error);
    return { hasError: true, error: error as never };
  }
}

