import React, { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import InputField from "../../Field";
import { getCompanyBranch } from "./services";

interface TextFieldProps {
  readonly required: boolean;
  readonly companyBranchId: number | null;
  readonly onChange: (value: number|undefined) => void
  readonly error?: boolean;
  readonly hasLocal?: boolean;
}

export interface CompanyBranchProps {
  readonly id: number;
  readonly company_branch_name: string;
}

export default function FilterAutocompleteCompanyBranch({
  required = false,
  companyBranchId,
  onChange,
  error=false,
  hasLocal = false,
}: TextFieldProps) {
  const [options, setOptions] = useState<readonly CompanyBranchProps[]>([]);
  const [value, setValue] = useState<CompanyBranchProps|null>(null)

  useEffect(() => {
    getCompanyBranch().then((response) => setOptions(response));
  }, []);

  useEffect(() => {
    const newValue = options.find((item) => item.id === companyBranchId) ?? null
    setValue(newValue)
  }, [companyBranchId, hasLocal, options])


  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option?.company_branch_name ?? ""}
      isOptionEqualToValue={(option, value) => option === value}
      value={value}
      disabled={hasLocal}
      onChange={(_, data) => {
        onChange(data?.id);
      }}
      renderInput={(params) => (
        <InputField
          {...params}
          label={"Local de Instalação"}
          required={required}
          error={error}
          helperText={error && 'O local de instalação é obrigatório'}
        />
      )}
    />
  );
}