import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { Button, Tooltip, Typography } from "@mui/material";
import CrudIndex, { IDataTable } from "../../../../components/CrudIndex";
import {
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
  GridSortModel,
  DataGrid,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../../core/Layout/MainLayout";
import TitleLayout from "../../../../core/Layout/TitleLayout";
import { useHookstate } from "@hookstate/core";
import { deleteUser, getIntegrator, getUsers } from "../../services";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import notify from "../../../../utils/notify";
import ContainerLayout from "../../../../core/Layout/ContainerLayout";

export default function ListOperator() {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    { field: "name", headerName: "Nome", flex: 1 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "cpf",
      headerName: "CPF",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Telefone",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <Tooltip title={"Editar"}>
          <GridActionsCellItem
            key={0}
            icon={<EditOutlinedIcon />}
            onClick={() => handleEdit(params.row.id)}
            label={"Editar"}
            id="btn-edit-user"
            className="btn-edit-user"
          />
        </Tooltip>,
        <Tooltip title={"Excluir"}>
          <GridActionsCellItem
            key={1}
            icon={<DeleteIcon />}
            onClick={() => handleDelete(params.row.id, params.row?.name)}
            label={"Excluir"}
            id="btn-delete-user"
            className="btn-delete-user"
          />
        </Tooltip>,
      ],
    },
  ];
  const rows = useHookstate<IDataTable[]>([]);
  const loading = useHookstate(false);
  const rowId = useHookstate<string>("");
  const rowName = useHookstate<string>("");
  const openDialog = useHookstate(false);
  const pageGrid = useHookstate<number>(0);
  const pageSizeOptions = [5, 15, 30, 50, 100];
  const limit = useHookstate<number>(pageSizeOptions[1]);
  const filters = useHookstate<any>(null);
  const sort = useHookstate<GridSortModel>([]);

  function handleDelete(id: string, name: string) {
    openDialog.set(true);
    rowId.set(id);
    rowName.set(name);
  }

  const handleDialogClose = () => {
    openDialog.set(false);
  };

  const handleDialogCloseFunction = () => {
    try {
      deleteUser(rowId.get()).then((response) => {
        notify({
          message: "Operador deletado com sucesso!",
          type: "success",
        });
        openDialog.set(false);
        getData();
      });
    } catch {
      notify({
        message: "Algo deu errado, tente novamente mais tarde!",
        type: "error",
      });
    }
  };

  function handleEdit(id: string) {
    navigate(`/editar/operador/${id}`);
  }

  useEffect(() => {
    getData();
  }, [pageGrid.get(), limit.get()]);

  async function getData() {
    loading.set(true);

    return await getIntegrator(pageGrid.get(), limit.get())
      .then((operator) => {
        rows.set(operator);
      })
      .finally(() => loading.set(false));
  }

  return (
    <MainLayout>
      <Grid
        container
        size={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          paddingRight: 2,
          paddingBottom: 2
        }}
      >
        <TitleLayout>Operador</TitleLayout>
        <Grid size={{ xs: 12, sm: 5, md: 3, lg: 2 }} sx={{ display: "flex" }}>
          <ConfirmButton
            label="Adicionar Operador"
            onConfirmClick={() => navigate("/cadastro/operador")}
            sx={{ padding: 2 }}
          />
        </Grid>
      </Grid>
      <ContainerLayout>
        <Grid container size={12} sx={{ height: "100%" }}>
          <DataGrid
            localeText={{
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  `mostrando ${
                    rows.get({ noproxy: true }).length
                  } registros de ${count}`,
              },
            }}
            sx={{
              border: 0,
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
            autoHeight={true}
            pageSizeOptions={pageSizeOptions}
            loading={loading.get()}
            rows={rows.get({ noproxy: true })}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: limit.get(),
                },
              },
            }}
            onPaginationModelChange={({ page, pageSize }) => {
              pageGrid.set(page);
              limit.set(pageSize);
            }}
            pagination
            disableRowSelectionOnClick
            paginationMode={"server"}
            filterMode={"server"}
            rowCount={rows.get().length}
            slotProps={{
              pagination: {
                labelRowsPerPage: "Operadores por página",
              },
            }}
            disableColumnSelector={true}
          />
        </Grid>
      </ContainerLayout>

      <ConfirmDialog
        handleAgree={handleDialogCloseFunction}
        handleClose={handleDialogClose}
        open={openDialog}
        AgreeLabel="Confirmar"
        title={"Confirmação"}
        moreInformation={
          <Typography>Deseja remover o operador {rowName.get()}?</Typography>
        }
      />
    </MainLayout>
  );
}
