import React, {createContext, useContext, useEffect, useState} from 'react';
import {useAuth, hasAuthParams} from 'react-oidc-context';
import SplashFeedback from '../Feedbacks/SplashFeedback';

interface AutheticationContextType {
    isAuthenticated: boolean,
    user: any,
    userGroup: Array<any>,
    userRoles: Array<any>,
    checkGroup: (allowedGroups:Array<any>)=> void,
    checkIsIntegradorAdmin: Function,
    checkIsAdmin: Function,
    checkIsIntegrador: Function
    checkIsIntegradorOperador: Function
    checkIsMonitoring: Function
    checkIsRecepcao: Function
    checkIsVistoria: Function
}

export const AutheticationContext = createContext<AutheticationContextType>({
    isAuthenticated: false,
    userGroup: [],
    userRoles:[],
    user: null,
    checkGroup: ()=> {},
    checkIsIntegradorAdmin: ()=>{},
    checkIsAdmin: ()=> {},
    checkIsIntegrador: ()=>{},
    checkIsIntegradorOperador: ()=>{},
    checkIsMonitoring: () => {},
    checkIsRecepcao: () =>{},
    checkIsVistoria: () => {}
});


interface AutheticationContextProviderProps {
 children: React.ReactNode;
}

function AutheticationContextProvider({children}: AutheticationContextProviderProps) {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<any>(null);
    const [userGroup, setUserGroup] = useState<Array<any>>([]);
    const [userRoles, setUserRoles] = useState<Array<any>>([]);
    
    if (auth.error) {
        console.log(auth.error, 'Erro ')
    }
    
    const GROUPS = [
        {   
            name:'integrador', 
            roles: [
                {name: 'administrador'},
                {name: 'operador'} 
            ]
        },
        {   
            name:'cidadão', 
            roles: []
        },
    ]

    const checkGroup = async (allowedGroups: Array<any>) => {

        if(userGroup.includes('administrador'))
            return true 

        if(userGroup.length > 0 && allowedGroups.length > 0 ){
            const canAccess = allowedGroups.some((g)=>{
                return userGroup.includes(g)
            })
            return canAccess;
        }

        return false
    }

    function  checkIsIntegradorAdmin():boolean {

        if(userGroup.includes('integrador')){
            
            return userRoles.includes('administrador');
        }

        return false
    }

    function checkIsAdmin(): boolean {
    
        if(userGroup !== undefined && userGroup !== null){
            
            return userGroup.includes('administrador');
        }

        return false
    }

    function checkIsIntegrador(): boolean {
    
        if(userGroup !== undefined && userGroup !== null){
            
            return userGroup.includes('integrador');
        }

        return false
    }

    function  checkIsIntegradorOperador():boolean {
       
        if(userGroup.includes('integrador')){
            
            return userRoles.includes('operador');
        }

        return false
    }

    function  checkIsMonitoring():boolean {
       
        if(userGroup !== undefined && userGroup !== null){
            
            return userGroup.includes('monitoramento');
        }

        return false
    }

    function checkIsVistoria(): boolean {
    
        if(userGroup !== undefined && userGroup !== null){
            
            return userGroup.includes('vistoria');
        }

        return false
    }
    function checkIsRecepcao(): boolean {
    
        if(userGroup !== undefined && userGroup !== null){
            
            return userGroup.includes('recepcao');
        }

        return false
    }


    useEffect(() => {
        if (
            !hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator && 
            !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinRedirect();
            setHasTriedSignin(true);
            
        } else {
            setHasTriedSignin(false);
        }
        setIsAuthenticated(auth.isAuthenticated)
   }, [auth, hasTriedSignin]);

    useEffect(() => {
        if (isAuthenticated) {
            
            setUser(auth?.user?.profile)
            setUserGroup(auth?.user?.profile.groups  as never)
            setUserRoles(auth?.user?.profile.roles  as never)
        }
    }, [isAuthenticated]);


   
 return (
  <AutheticationContext.Provider 
    value={{
        isAuthenticated,
        user,
        userGroup,
        userRoles,
        checkGroup,
        checkIsIntegradorAdmin,
        checkIsAdmin,
        checkIsIntegrador,
        checkIsIntegradorOperador,
        checkIsMonitoring, 
        checkIsRecepcao,
        checkIsVistoria
    }}
    >
        <>
            {!auth.isLoading && isAuthenticated?(
                children
            ):(
                <SplashFeedback open={true}/>
            )}
        </>
  </AutheticationContext.Provider>
 );
}

export default AutheticationContextProvider;
export function useAuthetication() {
    return useContext(AutheticationContext);
}