import {Socket} from 'socket.io-client';

type TrackerSensorData = {
    readonly code: string;
    readonly plate?: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly time: Date;
}

export function socketEvents(socket: Socket) {
    socket.on("TRACKER_LOCATION", (message: TrackerSensorData) => {
        
    });
}