import React from "react";
import ListLinkSideBar from "../ListLinkSideBar";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";

import ListItemSideBar from "../ListItemSideBar";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Collapse from "@mui/material/Collapse";
import ListSubLinkSideBar from "../ListSubLinkSideBar";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';


export default function MenuVistoria({
  hiddenMenuLabel = false,
  onMenuClick = (menuItem: string) => {},
}) {
  const [openConfig, setOpenConfig] = React.useState<boolean>(false);

  const handleMenuClick = (menuItem: string) => {
    onMenuClick(menuItem);
  };
  const openSubMenuConfig = () => {
    setOpenConfig(!openConfig);
  };

  return (
    <>
    
    <ListLinkSideBar
        icon={<TravelExploreIcon fontSize="medium" />}
        text={"Localizar Rastreador"}
        showText={!hiddenMenuLabel}
        link="/admin/rastreadores/localizar"
        handleClick={() => handleMenuClick("/admin/rastreadores/localizar")}
      />
        
 

      <ListLinkSideBar
        //activeMenu={activeMenu === "tracks-register"}
        icon={<HomeRepairServiceOutlinedIcon fontSize="medium" />}
        text={"Serviços"}
        showText={!hiddenMenuLabel}
        link="/servicos"
        handleClick={() => handleMenuClick("servicos")}
      />

   
    </>
  );
}
