import {  Tab, Tabs, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import MainLayout from "../../../../core/Layout/MainLayout";
import TabPanel from "../../../../components/TabPanel";
import { ICitizen } from "../../components/SearchCitizenTab";
import { useHookstate } from "@hookstate/core";
import KeywordRegisterTab from "../../components/KeywordRegisterTab";
import SummaryTab from "../../components/SummaryTab";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { getTrackById, updateTracker } from "../../services";
import notify from "../../../../utils/notify";
import ConfirmLocationTab from "../../components/ConfirmLocationTab";
import UploadPhotoTab from "../../components/UploadPhotoTab";
import { ITrack } from "../../interface";
import ContainerLayout from "../../../../core/Layout/ContainerLayout";
import TitleLayout from "../../../../core/Layout/TitleLayout";
import { useLocation, useNavigate } from "react-router-dom";
import SubtitleLayout from "../../../../core/Layout/SubtitleLayout";
import GridDataDisplay from "../../../../components/DataDisplay/GridDataDisplay";
import { sanitizerName } from "../../../../utils/StringsManipulationsUtil";
import { setPlateMask } from "../../../../utils/PlateUtil";
import { useEffect, useState } from "react";

interface ITrackInstallation {
  readonly citizen: ICitizen;
  readonly trackCode: string;
  readonly plate: string;
  readonly trackChip: string;
  readonly brand: string;
  readonly trackId: number;
}

function TracksInstallationPage() {
  const [trackCitizen, setTrackCitizen]  = useState<any>(null)
  const { state } = useLocation();
  const {
    citizen,
    trackCode,
    plate,
    trackChip,
    brand,
    trackId,
  }: ITrackInstallation = state;
  const navigate = useNavigate();

  const keyword = useHookstate("");
  const confirmKeyword = useHookstate("");
  const openConfirmDialog = useHookstate(false);
  const value = useHookstate(0);
  const image = useHookstate<string | ArrayBuffer | ImageData | null>(null);
  const loading = useHookstate(false);
  

  const getTrackerData = (): ITrack => {
    const imageObj = image.get() ? { image: image.get() } : {};

    return {
      keyword: keyword.get(),
      plate: plate,
      trackCode: trackCode,
      userId: citizen.id,
      trackChip: trackChip,
      id: trackId,
      ...imageObj,
    };
  };

  const handleSuccess = () => {
    openConfirmDialog.set(true);
    notify({
      message: `Rastreador instalado com sucesso`,
      type: "success",
    });
  };

  const handleErrors = (error: any) => {
    if (error?.invalid_params && error.invalid_params.length) {
      error.invalid_params?.map((item: { reason: string }) =>
        notify({
          message: `${item.reason}`,
          type: "error",
        })
      );
    } else if (error?.details.length) {
      notify({
        message: `${error.details}`,
        type: "error",
      });
    } else {
      notify({
        message: `Algo deu errado, tente novamente mais tarde.`,
        type: "error",
      });
    }
  };

  const submitForm = async () => {
    try {
      loading.set(true);
      const trackerData = getTrackerData();

      const { hasError, error } = await updateTracker(trackerData);

      if (hasError) {
        handleErrors(error);
      } else {
        handleSuccess();
      }
    } catch (err) {
      notify({
        message: `Algo deu errado, tente novamente mais tarde.`,
        type: "error",
      });
    } finally {
      loading.set(false);
    }
  };

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const getOneTrack = async (id: number) => {
    await getTrackById(id)
      .then(({response, hasError}) => {
        if(!hasError){
          console.log(response?.data)
          setTrackCitizen(response?.data)
        }
       
      })
      .catch(() => {
        notify({ message: `Não foi possível carregar as informações.`, type: "error" });
      })
      //.finally(() =>);
  };

  useEffect(()=>{
    if(trackId !== undefined && trackId !== null){
      getOneTrack(trackId)
    }
    
  }, [state])

  return (
    <MainLayout>
      <TitleLayout>Instalação do Rastreador</TitleLayout>
      {trackCitizen && (
        <Grid size={{xs:12}} sx={{pt:2, pl:0.5, pb:2}}>
          <GridDataDisplay titulo='Cidadã(o)' info={sanitizerName(trackCitizen?.user?.name)}/>
          <GridDataDisplay titulo='Placa' info={setPlateMask(trackCitizen?.plate)}/>
          <GridDataDisplay titulo='Código do Rastreador' info={trackCitizen?.trackCode} />
        </Grid>
      )}
      <ContainerLayout minHeight="50%">
        <Tabs value={value.get()} aria-label="basic tabs example">
          <Tab label="Confirmar Localização" {...tabProps(0)} />
          <Tab label="Enviar Foto" {...tabProps(1)} />
          <Tab label="Chave de Rastreamento" {...tabProps(2)} />
          <Tab label="Ver Resumo" {...tabProps(3)} />
        </Tabs>

        <TabPanel value={value.get()} index={0}>
          <ConfirmLocationTab value={value} trackCode={trackCode} />
        </TabPanel>
        <TabPanel value={value.get()} index={1}>
          <UploadPhotoTab value={value} image={image} />
        </TabPanel>
        <TabPanel value={value.get()} index={2}>
          <KeywordRegisterTab
            value={value}
            keyword={keyword}
            confirmKeyword={confirmKeyword}
          />
        </TabPanel>
        <TabPanel value={value.get()} index={3}>
          <SummaryTab
            citizen={citizen.id}
            plate={setPlateMask(trackCitizen?.plate)}
            trackId={trackCode}
            value={value}
            brand={brand ?? ""}
            trackChip={trackChip}
            confirmAction={() => submitForm()}
            image={image.get()}
            loadingButton={loading.get()}
            idTrack={trackId}
            onUpdated={()=>{
              getOneTrack(trackId)
            }}
          />
        </TabPanel>
      </ContainerLayout>

      <ConfirmDialog
        title="Rastreador instalado com sucesso!"
        moreInformation={
          <Typography>O rastreador do cidadão já está ativo.</Typography>
        }
        open={openConfirmDialog}
        handleAgree={() => {
          openConfirmDialog.set(false);
          navigate('/servicos');
        }}
      />
    </MainLayout>
  );
}

export default TracksInstallationPage;
