import Typography  from "@mui/material/Typography";
import Grid  from "@mui/material/Grid2";
import React, { ReactNode } from "react";
import { SxProps, Theme } from "@mui/material";

type LayoutProps = {
    readonly children: ReactNode;
    readonly sx?: SxProps<Theme>;
}

export default function SubtitleLayout({children, sx}: LayoutProps) {
    return (
        <Grid container >
            <Typography component='h2' sx={{fontSize: '1.25rem', margin: 0, marginBottom:2,  marginTop:2, ...sx}}>
                {children}
            </Typography>
        </Grid>    
    )
}