import Typography  from "@mui/material/Typography";
import React, { ReactNode } from "react";

type LayoutProps = {
    readonly children: ReactNode;
}

export default function LabelTextBoldDataDisplay({ children}: LayoutProps) {
    return (
        
    <Typography component='p' sx={{fontWeight: 700, color: 'text.secondary', marginRight: 0.5}}>
       {children}
    </Typography>
    )
}
